




import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./BookGroup.css";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Modal, Button } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";

function BookGroup() {
  // State variables
  const [BookGroupName, setBookGroupName] = useState('');
  const [bookgroups, setBookgroups] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [id, setId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState('');

  // Fetch book groups data
  useEffect(() => {
    fetchBookgroups();
  }, []);

  const fetchBookgroups = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/BookGroupget.php");
      setBookgroups(response.data);
    } catch (error) {
      toast.error("Error fetching book groups:", error);
    }
  };

  const resetForm = () => {
    setBookGroupName("");
    setIsModalOpen(false);
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setEditingIndex(-1);
  };

  const handleEdit = (row) => {
    const book = bookgroups[row.index];
    setBookGroupName(book.BookGroupName);
    setEditingIndex(row.index);
    setId(book.Id);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleDelete = (index) => {
    const newBookgroups = bookgroups.filter((_, i) => i !== index);
    setBookgroups(newBookgroups);
    toast.error("Book Group Deleted Successfully!");
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!BookGroupName) {
      formErrors.BookGroupName = "Book Group Name is required.";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    if (!validateForm()) return;

    const data = { BookGroupName };
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/BookGroupupdate.php"
      : "https://publication.microtechsolutions.co.in/php/BookGrouppost.php";

    if (isEditing) {
      data.Id = id;
    }

    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (isEditing) {
        toast.success('Book Group updated successfully!');
      } else {
        toast.success('Book Group added successfully!');
      }
      resetForm();
      fetchBookgroups(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: 'SrNo',
      header: "Sr.No",
      size: 50,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "BookGroupName",
      header: "Book Group",
      size: 50,
    },
    {
      accessorKey: "actions",
      header: "Actions",
      size: 150,
      Cell: ({ row }) => (
        <div>
         <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit 
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
        </div>
      ),
    },
  ], [bookgroups]);

  const table = useMaterialReactTable({ columns, data: bookgroups,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
   });

  return (
    <div className="bookgroup-container">
      <h1>Book Group Master</h1>
      <div className="bookgrouptable-master">
        <div className="bookgrouptable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF",
               fontWeight: "700", background:'#0a60bd', width:'15%' }}
          >
            New
          </Button>
          <div className="bookgrouptable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>

        {isModalOpen && <div className="bookgroup-overlay" onClick={() => setIsModalOpen(false)} />}

        <Modal open={isModalOpen}>
          <div className="bookgroup-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}
            >
              {editingIndex >= 0 ? "Edit Book Group " : "Add Book Group "}
            </h1>
            <form className="bookgroup-form">
              <div>
                <label className="bookgroup-label">
                  Book Group <b className="required">*</b>
                </label>
                <div>
                  <input
                    type="text"
                    id="BookGroupName"
                    name="BookGroupName"
                    value={BookGroupName}
                    onChange={(e) => setBookGroupName(e.target.value)}
                    className="bookgroup-control"
                    placeholder="Enter Book Group Name"
                  />
                  <div>
                    {errors.BookGroupName && <b className="error-text">{errors.BookGroupName}</b>}
                  </div>
                </div>
              </div>
            </form>
            <div className="bookgroup-btn-container">
              <Button
                type="submit"
                style={{
                  background: "#0a60bd",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                {editingIndex >= 0 ? "Update" : "Save"}
              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                style={{
                  background: "red",
                  color: "white",
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
}

export default BookGroup;
