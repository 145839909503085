import React from 'react'

function Stockdaybook() {
  return (
    <div>
      Stockdaybook
      Stockdaybook
    </div>
  )
}

export default Stockdaybook
