import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./State.css";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Modal, Button } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";


function Area() {
  
  const [AreaName, setAreaName] = useState("");
  const [areas, setAreas ] = useState([])
  const [convassor, setConvassor] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [id, setId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState('');



 
  useEffect(()=>{
    fetchAreas();
   },[])


  const fetchAreas = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Areaget.php");
      
      setAreas(response.data);
    } catch (error) {
      toast.error("Error fetching Areas:", error);
    }
  };
 
 
 

  
  const resetForm = () => {
    setAreaName("");
   
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setEditingIndex(-1);
  };



  const handleEdit = (row) => {
    const area = areas[row.index];
    setAreaName(area.AreaName);
    setEditingIndex(row.index);
    setId(area.Id);
    setIsEditing(true);
    setIsModalOpen(true);
  };
  

  const handleDelete = (index) => {
    const newAreas = areas.filter((_, i) => i !== index);
    setAreas(newAreas);
    toast.error("Area Deleted Successfully!")
  };

  const validateForm = ()=> {
    let formErrors = {};
    let isValid = true;

    
    if (!AreaName) {
        formErrors.AreaName = "Area Name is required.";
        isValid = false;
    
    }

    
    setErrors(formErrors);
    return isValid;
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
      AreaName : AreaName
    };
  
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Areaupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Areapost.php";
  
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Area updated successfully!');
      } else {
        toast.success('Area added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchAreas(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
  

 

  


 

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "AreaName",
        header: "Area",
        size: 50,
      },
      
      
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
              <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [areas]
  );

  

  const table = useMaterialReactTable({
    columns,
    data: areas, muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="state-container">
      <h1>Area Master</h1>
      <div className="statetable-master">
        <div className="statetable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", 
              fontWeight: "700", background:'#0a60bd', width:'15%' }}
          >
            New
          </Button>
         
          <div className="statetable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>
        {isModalOpen && <div className="state-overlay" onClick={() => setIsModalOpen(false)} />}

        <Modal open={isModalOpen}>
          <div className="state-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}
            >
              {editingIndex >= 0 ? "Edit Area " : "Add New Area "}
            </h1>

            <form onSubmit={handleSubmit} className="state-form">
              <div>
                <label className="state-label">Area<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="AreaName"
                    name="AreaName"
                    value={AreaName}
                    onChange={(e) => setAreaName(e.target.value)}
                    className="state-control"
                    placeholder="Enter Area"
                  />

<div>
                    {errors.AreaName && <b className="error-text">{errors.AreaName}</b>}
                      </div> 
                </div>
              </div>

             
              </form>
              <div className="state-btn-container">
                <Button
                  type="submit"
                  style={{
                    background: "#0a60bd",
                    color: "white",
                  }}
                  onClick={handleSubmit}
                >
                   {editingIndex >= 0 ? "Update" : "Save"}
                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
              </div>
            
          </div>
        </Modal>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default Area;
