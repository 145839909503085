import React, { useState, useMemo, useEffect } from "react";
import './Paperoutforprinting.css';
import Select from 'react-select';
import axios from "axios";
import { Button, TextField, Modal } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
import moment from 'moment';
import qs from 'qs';




function Paperoutforprinting() {

  const [ChallanNo, setChallanNo] = useState('');
  const [ChallanDate, setChallanDate] = useState('');
  const [GodownId, setGodownId] = useState('');
  
  const [AccountId, setAccountId] = useState('');
  const [TimeOfRemoval, setTimeOfRemoval] = useState('');
  const [VehicleNo, setVehicleNo] = useState('');
  const [NameOfDriver, setNameOfDriver] = useState('');
 
  
 
   const [editingIndex, setEditingIndex] = useState(-1);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [id, setId] = useState('');
 
   const [paperoutdetailId, setPaperoutdetailId] = useState('');
 const [errors, setErrors] = useState('');
 const [isEditing, setIsEditing] = useState(false);
 const [paperoutheaders, setPaperoutheaders] = useState([]);
 const [paperoutdetails, setPaperoutdetails] = useState([]);
 
 
 
 
 //Dropdown for ID's
 const [godownOptions, setGodownOptions] = useState([]);
 const [accountOptions, setAccountOptions] = useState([]);
  const [paperOptions, setPaperOptions] = useState([]);
 const [PaperId, setPaperId] = useState('');
 const [rows, setRows] = useState([
  { PaperId: "", 
    MillName: "", 
    Bundles: "", 
    Quantity: "",
     Unit: "",
      Papers: "", 
      CurrentStock: ""
     },
  // Add more rows as needed
]);

 
 
 
   useEffect(()=>{
    fetchPaperheaders();
    fetchPaperdetails();
   }, []);
 
   const fetchPaperheaders = async () => {
     try {
       const response = await axios.get("https://publication.microtechsolutions.co.in/php/Paperoutwardheaderget.php");
       setPaperoutheaders(response.data);
     } catch (error) {
       toast.error("Error fetching Paper headers :", error);
     }
   };
 
   // Fetch the purchase details
   const fetchPaperdetails = async () => {
     try {
       const response = await axios.get("https://publication.microtechsolutions.co.in/php/Paperoutwarddetailget.php");
       setPaperoutdetails(response.data);
     } catch (error) {
       toast.error("Error fetching Paper details:", error);
     }
   };
 
 
   
 
 
 
 
     
   
 
 
    
   
   
 
   const handleAddRow = () => {
     setRows([
       ...rows,
       {
        PaperId: '', // Default value for the first row
        MillName: '',
        Bundles: '',
        Quantity: '',
        Unit: '',
        Papers: '',
        CurrentStock: ''
      },
     ]);
   };
 
 
 
   const handleDeleteRow = (index) => {
     const updatedRows = rows.filter((_, i) => i !== index);
     setRows(updatedRows);
     toast.error('Paper details Deleted Succefully')
   };
 
 


   useEffect(()=> {
    fetchPapers();
    fetchAccounts();
    fetchGodowns();
   }, [])
 
 
 
   const fetchPapers = async () => {
     try {
       const response = await axios.get("https://publication.microtechsolutions.co.in/php/PaperSizeget.php");
       const paperOptions = response.data.map((paper) => ({
         value: paper.Id,
         label: paper.PaperSizeName,

       }));
       setPaperOptions(paperOptions);
     } catch (error) {
       toast.error("Error fetching Papers:", error);
     }
   };
 
   
 
   const fetchAccounts = async () => {
     try {
       const response = await axios.get("https://publication.microtechsolutions.co.in/php/Accountget.php");
       const accountOptions = response.data.map((acc) => ({
         value: acc.Id,
         label: acc.AccountName,
       }));
       setAccountOptions(accountOptions);
     } catch (error) {
       toast.error("Error fetching Accounts:", error);
     }
   };


   const fetchGodowns = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Godownget.php");
      const godownOptions = response.data.map((god) => ({
        value: god.Id,
        label: god.GodownName,
      }));
      setGodownOptions(godownOptions);
    } catch (error) {
      toast.error("Error fetching Accounts:", error);
    }
  };
 
 
  
 
  
 
 
   
  
 
   const resetForm = () => {
    setChallanDate('');
    setChallanNo('');
    setGodownId('');
    setAccountId('');
    setTimeOfRemoval('');
    setVehicleNo('');
    setNameOfDriver('');
     setRows([
       {
        PaperId: '', // Default value for the first row
        MillName: '',
        Bundles: '',
        Quantity: '',
        Unit: '',
        Papers: '',
        CurrentStock: ''
       },
     ]);
 };
 
 

 
 
   const handleNewClick = () => {
     resetForm();
     setIsModalOpen(true);
     setIsEditing(false);
     setEditingIndex(-1);
   };


   const handleEdit = (row) => {
    const paperheader = paperoutheaders[row.index];
  
    // Filter purchase details to match the selected PurchaseId
    const paperdetail = paperoutdetails.filter(detail => detail.PaperOutwardId === paperheader.Id);
  


 // Convert date strings to DD-MM-YYYY format
 const convertDateForInput = (dateStr) => {
  if (typeof dateStr === 'string' && dateStr.includes('-')) {
      const [year, month, day] = dateStr.split(' ')[0].split('-');
      return `${year}-${month}-${day}`;
  } else {
      console.error('Invalid date format:', dateStr);
      return ''; // Return an empty string or handle it as needed
  }
};


   
    // Map the details to rows
    const mappedRows = paperdetail.map(detail => ({
      PaperOutwardId: detail.PaperOutwardId,
      PaperId: detail.PaperId,
      MillName: detail.MillName,
      Bundles: detail.Bundles,
      Quantity: detail.Quantity,
      Unit: detail.Unit,
      Papers: detail.Papers,
      CurrentStock: detail.CurrentStock,
      Id: detail.Id, // Include the detail Id in the mapped row for tracking
    }));

    const challandate = convertDateForInput(paperheader.ChallanDate.date);

    const timeofremoval = convertDateForInput(paperheader.TimeOfRemoval.date);

  
    setChallanNo(paperheader.ChallanNo);
    setChallanDate(challandate);
    setGodownId(paperheader.GodownId);
    setAccountId(paperheader.AccountId);
    setTimeOfRemoval(timeofremoval);
    setVehicleNo(paperheader.VehicleNo);
    setNameOfDriver(paperheader.NameOfDriver);
  

    console.log(paperheader, 'Paper Header')
    console.log(paperdetail, 'paper detail')

    setRows(mappedRows);
  
    setIsEditing(true);
    setIsModalOpen(true);
    setEditingIndex(row.index);
    setId(paperheader.Id);
  
    // Determine which specific detail to edit
    const specificDetail = paperdetail.find(detail => detail.Id === row.original.Id);
    if (specificDetail) {
      setPaperoutdetailId(specificDetail.Id); // Set the specific detail Id
    }

    fetchPaperdetails();
  };
 
 
   
 
   
  

//   const handleEdit = (row) => {
//     const paperoutheader = paperoutheaders[row.index];
//     const paperoutdetail = paperoutdetails.filter(detail => detail.PaperOutwardId === paperoutheader.Id);

//     const mappedRows = paperoutdetail.map(detail => ({
//       PaperOutwardId: detail.PaperOutwardId,
//       PaperId: detail.PaperId,
//       MillName: detail.MillName,
//       Bundles: detail.Bundles,
//       Quantity: detail.Quantity,
//       Unit: detail.Unit,
//       Papers: detail.Papers,
//       CurrentStock: detail.CurrentStock,
//       Id: detail.Id,
//     }));

//     const convertDateForInput = (dateStr) => {
//         const [year, month, day] = dateStr.split('-');
//         return `${year}-${month}-${day}`;
//     };

//     const challandate = convertDateForInput(paperoutheader.ChallanDate.date);
//     const timeofremoval = convertDateForInput(paperoutheader.TimeOfRemoval.date);

//     setChallanNo(paperoutheader.ChallanNo);
//     setChallanDate(challandate);
//     setGodownId(paperoutheader.GodownId);
//     setAccountId(paperoutheader.AccountId);
//     setTimeOfRemoval(timeofremoval);
//     setVehicleNo(paperoutheader.VehicleNo);
//     setNameOfDriver(paperoutheader.NameOfDriver);
//     setRows(mappedRows);

//     setIsEditing(true);
//     setIsModalOpen(true);
//     setEditingIndex(row.index);
// };


  const handleDelete = (index) => {
    setPaperoutheaders((prevPaperoutheaders) =>
      prevPaperoutheaders.filter((_, i) => i !== index)
    );
    toast.error('Paper details Deleted Successfully!');
  };

 
 
   const validateForm = () => {
    let formErrors = {};
    let isValid = true;
  
    if (!ChallanNo) {
      isValid = false;
      formErrors.ChallanNo = "Challan No is required";
    }
  
    if (!ChallanDate) {
      isValid = false;
      formErrors.ChallanDate = "Challan Date is required";
    }
  
    if (!GodownId) {
      isValid = false;
      formErrors.GodownId = "Godown Id is required";
    }


    if (!AccountId) {
      isValid = false;
      formErrors.AccountId = "Account Id is required";
    }
  
    if (!TimeOfRemoval) {
      isValid = false;
      formErrors.TimeOfRemoval = "Time Of removal is required";
    }

    if (!VehicleNo) {
      isValid = false;
      formErrors.VehicleNo = "Vehicle No is required";
    }
  
    if (!NameOfDriver) {
      isValid = false;
      formErrors.NameOfDriver = "Name Of Driver is required";
    }
  
  
    setErrors(formErrors);
    return isValid;
  };
  
 
 
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    const formattedChallandate = moment(ChallanDate).format('YYYY-MM-DD');
    const formattedTimeofremoval = moment(TimeOfRemoval).format('YYYY-MM-DD');
  
    const paperData = {
      Id: isEditing ? id : '',  // Include the Id for updating, null for new records
      ChallanNo: ChallanNo,
      ChallanDate: formattedChallandate,
      GodownId: GodownId,
      AccountId: AccountId,
      TimeOfRemoval: formattedTimeofremoval,
      VehicleNo: VehicleNo,
      NameOfDriver: NameOfDriver
      
    };
  
    try {
      const paperurl = isEditing
        ? "https://publication.microtechsolutions.co.in/php/Paperoutwardheaderupdate.php"
        : "https://publication.microtechsolutions.co.in/php/Paperoutwardheaderpost.php";
  
      // Submit purchase header data
      const response = await axios.post(paperurl, qs.stringify(paperData), {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
  
      const paperOutwardId = isEditing ? id : parseInt(response.data.Id, 10);
  
      for (const row of rows) {
        const rowData = {
          PaperOutwardId: paperOutwardId,
          PaperId: parseInt(row.PaperId, 10),
          MillName: row.MillName,
          Bundles: parseInt(row.Bundles, 10),
          Quantity: parseFloat(row.Quantity),
          Unit: row.Unit,
          Papers: parseInt(row.Papers, 10),
          CurrentStock: parseInt(row.CurrentStock, 10),
          Id: row.Id,
        };
      
        console.log('Submitting Row Data:', rowData);
      
        const paperdetailurl = isEditing && row.Id
          ? "https://publication.microtechsolutions.co.in/php/Paperoutwarddetailupdate.php"
          : "https://publication.microtechsolutions.co.in/php/Paperoutwarddetailpost.php";
      
        await axios.post(paperdetailurl, qs.stringify(rowData), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
      }
      
  
      fetchPaperheaders(); 
      fetchPaperdetails();
      setIsModalOpen(false);
      toast.success(isEditing ? 'Paper & Paper Details updated successfully!' : 'Paper & Paper Details added successfully!');
      resetForm(); // Reset the form fields after successful submission
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
 
 
 const handleInputChange = (index, field, value) => {
  const updatedRows = [...rows];
  updatedRows[index][field] = value;
  setRows(updatedRows);
};




 
 
 
 
 
 
 
 
 
 
 
 
 
 
  
 
   const columns = useMemo(
     () => [
 
       {
         accessorKey: 'SrNo',
         header: "Sr.No",
         size: 50,
         Cell: ({ row }) => row.index + 1,
       },
      
       {
         accessorKey: "ChallanNo",
         header: "Challan No",
         size: 50,
       },

       {
        accessorKey: "VehicleNo",
        header: "Vehicle No",
        size: 50,
      },
       {
         accessorKey: "ChallanDate.date",
         header: "Challan Date",
         size: 50,
         Cell: ({ cell }) => {
           // Using moment.js to format the date
           const date = moment(cell.getValue()).format('DD-MM-YYYY');
           return <span>{date}</span>;
         },
       },
       
      
       {
         accessorKey: "actions",
         header: "Actions",
         size: 150,
         Cell: ({ row }) => (
           <div>
             <Button
               onClick={() => handleEdit(row)}
               style={{
                 background: "#0a60bd",
                 color: "white",
                 marginRight: "5px", 
               }}>
                           Edit
 
             </Button>
             <Button
               onClick={() => handleDelete(row.index)}
               style={{
                 background: "red",
                 color: "white",fontSize:'22px'
               }}>
               <RiDeleteBin5Line/>
             </Button>
 
 
           </div>
         ),
       },
     ],
     [paperoutheaders]
   );
 
 
   const table = useMaterialReactTable({
     columns,
     data: paperoutheaders,
     muiTableHeadCellProps: {
       style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
       },
     },
   });
 


  return (
    <div className="paperout-container">
     <h1>
      Paper Outward for Book Printing
     </h1>

     <div className="paperouttable-master">
       <div className="paperouttable1-master">
         <Button
          onClick={handleNewClick}
          style={{ color: "#FFFF",
             fontWeight: "700", background:'#0a60bd', width:'15%' }}
          >
          New
        </Button>
        <div className="paperouttable-container">
          <MaterialReactTable table={table} />
        </div>
      </div>



      {isModalOpen && <div className="paperout-overlay" onClick={() => setIsModalOpen(false)} />}

      <Modal open={isModalOpen}>
        <div className="paperout-modal">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "500",
              margin: "2px",
            }}>
            {editingIndex >= 0 ? "Edit Paper Outward for Printing"  : "Add Paper Outward for Printing"} 
          </h1>
          <form  className="paperout-form">
            

            
             


              <div>
                <label className="paperout-label">Challan No<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="ChallanNo"
                   name="ChallanNo" 
                  value={ChallanNo}
                  onChange={(e)=> setChallanNo(e.target.value)}
                  className="paperout-control"
                  placeholder="Enter Challan No"
                  />
                </div>

                <div>
                          {errors.ChallanNo && <b className="error-text">{errors.ChallanNo}</b>}
                        </div>
              </div>

              <div>
                <label className="paperout-label">Challan Date<b className="required">*</b>:</label>
                <div>
                  <input
                  type="date"
                  id="ChallanDate"
                  name="ChallanDate" 
                  value={ChallanDate}
                  onChange={(e)=> setChallanDate(e.target.value)}
                  className="paperout-control"
                  placeholder="Enter Challan Date"
                  />
                </div>

                <div>
                          {errors.ChallanDate && <b className="error-text">{errors.ChallanDate}</b>}
                        </div>
              </div>

              <div>
                <label className="paperout-label"> Godown Id<b className="required">*</b>:</label>
                <div>
                <Select
                 id="GodownId"
                 name="GodownId"
                 value={godownOptions.find((option) => option.value === GodownId)}
                 onChange={(option) => setGodownId(option.value)}
                 options={godownOptions} 
                 styles={{
                   control: (base) => ({
                     ...base,
                     width: "170px",
                            marginTop: "10px",
                            borderRadius: "4px",
                            border: "1px solid rgb(223, 222, 222)",
                             marginBottom: '5px'
                   }),
                   menu: (base) => ({
                    ...base,
                    zIndex: 100,
                  }),
                 }}
                  placeholder="Select Id"
                />  
                </div>

                <div>
                          {errors.GodownId && <b className="error-text">{errors.GodownId}</b>}
                        </div>
              </div>

              
              
              
             
              <div>
                <label className="paperout-label"> Account Id<b className="required">*</b>:</label>
                <div>
                <Select
                 id="AccountId"
                 name="AccountId"
                 value={accountOptions.find((option) => option.value === AccountId)}
                 onChange={(option) => setAccountId(option.value)}
                 options={accountOptions} 
                 styles={{
                   control: (base) => ({
                     ...base,
                     width: "170px",
                            marginTop: "10px",
                            borderRadius: "4px",
                            border: "1px solid rgb(223, 222, 222)",
                             marginBottom: '5px'
                   }),
                   menu: (base) => ({
                    ...base,
                    zIndex: 100,
                  }),
                 }}
                  placeholder="Select Acc id"
                />  
                </div>

                <div>
                          {errors.AccountId && <b className="error-text">{errors.AccountId}</b>}
                        </div>
              </div>


              <div>
                <label className="paperout-label">Time Of Removal<b className="required">*</b>:</label>
                <div>
                  <input
                  type="date"
                  id="TimeOfRemoval"
                   name="TimeOfRemoval" 
                  value={TimeOfRemoval}
                  onChange={(e)=> setTimeOfRemoval(e.target.value)}
                  className="paperout-control"
                  placeholder="Enter Time of removal"
                  />
                </div>

                <div>
                          {errors.TimeOfRemoval && <b className="error-text">{errors.TimeOfRemoval}</b>}
                        </div>
              </div>


              <div>
                <label className="paperout-label">Vehicle No<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="VehicleNo"
                  name="VehicleNo" 
                  value={VehicleNo}
                  onChange={(e)=> setVehicleNo(e.target.value)}
                  className="paperout-control"
                  placeholder="Enter Vehicle No"
                  />
                </div>

                <div>
                          {errors.VehicleNo && <b className="error-text">{errors.VehicleNo}</b>}
                </div>
              </div>

              <div>
                <label className="paperout-label">Name Of Driver<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="NameOfDriver"
                   name="NameOfDriver" 
                  value={NameOfDriver}
                  onChange={(e)=> setNameOfDriver(e.target.value)}
                  className="paperout-control"
                  placeholder="Enter Name of Driver"
                  />
                </div>

                <div>
                          {errors.NameOfDriver && <b className="error-text">{errors.NameOfDriver}</b>}
                        </div>
              </div>

             
</form>





  <div className="paperout-table">
<table>
  <thead>
    <tr>
      <th>Serial No</th>
      <th>Paper Id<b className="required">*</b></th>
      <th>Mill Name<b className="required">*</b></th>
      <th>Bundles<b className="required">*</b></th>
      <th>Quantity<b className="required">*</b></th>
      <th>Unit<b className="required">*</b></th>
      <th>Papers<b className="required">*</b></th>
      <th>CurrentStock<b className="required">*</b></th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
{rows.map((row, index) => (
  <tr key={index}>
    <td>{index + 1}</td>
    <td>
      <Select
        value={paperOptions.find((option) => option.value === row.PaperId)}
        onChange={(option) => handleInputChange(index, 'PaperId', option.value)}
        options={paperOptions}
        placeholder="Select Paper"
        styles={{
          control: (base) => ({
            ...base,
            width: "150px",
          }),

          menu: (base) => ({
            ...base,
            zIndex: 100,
          }),
        }}
      />
    </td>
    <td>
      <input
        type="text"
        value={row.MillName}
        onChange={(e) => handleInputChange(index, 'MillName', e.target.value)}
        placeholder="Enter MillName"
      />
    </td>
    <td>
      <input
        type="number"
        value={row.Bundles}
        onChange={(e) => handleInputChange(index, 'Bundles', e.target.value)}
        placeholder="Enter Bundles"
      />
    </td>
    <td>
      <input
        type="number"
        value={row.Quantity}
        onChange={(e) => handleInputChange(index, 'Quantity', e.target.value)}
        placeholder="Enter Quantity"
      />
    </td>
    <td>
      <input
        type="text"
        value={row.Unit}
        onChange={(e) => handleInputChange(index, 'Unit', e.target.value)}
        placeholder="Enter Unit "
      />
    </td>
    <td>
      <input
        type="number"
        value={row.Papers}
        onChange={(e) => handleInputChange(index, 'Papers', e.target.value)}

        placeholder="Enter Papers"
      />
    </td>
    <td>
      <input
        type="number"
        value={row.CurrentStock}
        onChange={(e) => handleInputChange(index, 'CurrentStock', e.target.value)}

        placeholder="Enter CurrentStock"
      />
    </td>
    <td>
<div style={{display:'flex', justifyContent:'space-between'}}>

    <Button
  onClick={handleAddRow}
  style={{ background: "#0a60bd", color: "white", marginRight: "5px" }}>
  Add 
</Button>
      <Button
        onClick={() => handleDeleteRow(index)}
        style={{ background: "red", color: "white" }}>
        <RiDeleteBin5Line />
      </Button></div>

    </td>
  </tr>
))}
</tbody>

</table>

</div>

              <div className="paperout-btn-container">
                <Button
                  type="submit" onClick={handleSubmit}
                  style={{
                    background: "#0a60bd",
                    color: "white",
                  }}>
                   {editingIndex >= 0 ? "Update" : "Save"}
                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}>
                  Cancel
                </Button>
              </div>
           
        </div>
      </Modal>
    </div>
    <ToastContainer/>
  </div>
  );
}

export default Paperoutforprinting;
