import React, { useState, useMemo, useEffect, useSyncExternalStore } from "react";
import './Convassordailyreport.css';
import Select from 'react-select';
import axios from "axios";
import { Button, TextField, Modal } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
import moment from 'moment';
import qs from 'qs';

function Convassordailyreport() {
  const [PurchaseReturnNo, setPurchaseReturnNo] = useState('');
  const [PurchaseReturnDate, setPurchaseReturnDate] = useState('');
  const [RefrenceNo, setRefrenceNo] = useState('');
  const [SupplierId, setSupplierId] = useState('');
  const [AccountId, setAccountId] = useState('');
 const [SubTotal, setSubTotal] = useState('');
 const [ Extra1, setExtra1] = useState('');
 const [Extra1Amount, setExtra1Amount] = useState('');
 const [ Extra2, setExtra2] = useState('');
 const [Extra2Amount, setExtra2Amount] = useState('');
 const [Total, setTotal] = useState('');
 const [TotalCopies, setTotalCopies] = useState('');
 const [Remark, setRemark] = useState(''); 

  const [editingIndex, setEditingIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState('');

  const [purchasereturndetailId, setPurchasereturndetailId] = useState('');
const [errors, setErrors] = useState('');
const [isEditing, setIsEditing] = useState(false);
const [purchasereturns, setPurchasereturns] = useState([]);
const [purchasereturndetails, setPurchasereturnDetails] = useState([]);




//Dropdown for ID's
const [bookOptions, setBookOptions] = useState([]);
const [accountOptions, setAccountOptions] = useState([]);
const [rows, setRows] = useState([
  {
    BookId: '', // Default value for the first row
    Copies: 0,
    Rate: 0,
    DiscountPercentage: 0,
    DiscountAmount: 0,
    Amount: 0,
  },
]);



  useEffect(()=>{
    fetchPurchasereturns();
    fetchPurchasereturnDetails();
    fetchBooks();
    fetchAccounts();
  }, []);

  const fetchPurchasereturns = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Purchasereturnheaderget.php");
      setPurchasereturns(response.data);
    } catch (error) {
      toast.error("Error fetching Purchase returns:", error);
    }
  };

  // Fetch the purchase details
  const fetchPurchasereturnDetails = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Purchasereturndetailget.php");
      // console.log(response.data, 'response of purchase return details')
      setPurchasereturnDetails(response.data);
    } catch (error) {
      toast.error("Error fetching Purchase return details:", error);
    }
  };


  // Calculation functions
  const calculateTotals = () => {
    let totalCopies = 0;
    let subtotal = 0;
    let total = 0;

    rows.forEach(row => {
      totalCopies += Number(row.Copies) || 0;
      subtotal += Number(row.DiscountAmount) || 0;
      total += Number(row.Amount) || 0;
    });
    setTotalCopies(totalCopies);
    setSubTotal(subtotal);
    setTotal(total);
  };




    
  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    
    // Update the value of the current field
    updatedRows[index][field] = value;
  
    // Calculate the Amount when Copies and Rate are entered
    if (field === 'Copies' || field === 'Rate') {
      const copies = updatedRows[index].Copies || 0;
      const rate = updatedRows[index].Rate || 0;
      updatedRows[index].Amount = copies * rate;
    }
  
    // Calculate the DiscountAmount and FinalAmount when DiscountPercentage is entered
    if (field === 'DiscountPercentage' || field === 'Copies' || field === 'Rate') {
      const discountPercentage = updatedRows[index].DiscountPercentage || 0;
      const amount = updatedRows[index].Amount || 0;
      updatedRows[index].DiscountAmount = (amount * discountPercentage) / 100;
      updatedRows[index].Amount = amount - updatedRows[index].DiscountAmount;
    }
  
    // Update the state with the new row data
    setRows(updatedRows);
    calculateTotals();
  };


   
  
  

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        BookId: "", // This will be empty for new rows
        SerialNo: "",
        Copies: "",
        Rate: "",
        DiscountPercentage: "",
        DiscountAmount: "",
        Amount: "",
      },
    ]);
    calculateTotals();
  };



  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
    calculateTotals();
  };





  const fetchBooks = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Bookget.php");
      const bookOptions = response.data.map((book) => ({
        value: book.Id,
        label: book.BookName,
      }));
      setBookOptions(bookOptions);
    } catch (error) {
      toast.error("Error fetching books:", error);
    }
  };

  

  const fetchAccounts = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Accountget.php");
      const accountOptions = response.data.map((acc) => ({
        value: acc.Id,
        label: acc.AccountName,
      }));
      setAccountOptions(accountOptions);
    } catch (error) {
      toast.error("Error fetching Accounts:", error);
    }
  };


  
 

  const resetForm = () => {
    setPurchaseReturnNo('');
    setPurchaseReturnDate('');
    setRefrenceNo('');
    setSupplierId('');
    setAccountId('');
    setSubTotal('');
    setExtra1('');
    setExtra1Amount('');
    setExtra2('');
    setExtra2Amount('');
    setTotal('');
    setTotalCopies('');
    setRemark('');
    setRows([
      {
        BookId: '',
        Copies: 0,
        Rate: 0,
        DiscountPercentage: 0,
        DiscountAmount: 0,
        Amount: 0,
      },
    ]);
};


useEffect(() => {
  calculateTotals();
}, [rows]);


  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setIsEditing(false);
    setEditingIndex(-1);
  };


  

  
  const handleEdit = (row) => {
    const purchasereturn = purchasereturns[row.index];
  
    // Filter purchase details to match the selected PurchaseId
    const purchasereturndetail = purchasereturndetails.filter(detail => detail.PurchaseReturnId === purchasereturn.Id);

    // Map the details to rows
    const mappedRows = purchasereturndetail.map(detail => ({
      // PurchaseReturnId: detail.PurchaseReturnId,
      
      PurchaseReturnId:detail.PurchaseReturnId,
      BookId: detail.BookId,
      Copies: detail.Copies,
      Rate: detail.Rate,
      DiscountPercentage: detail.DiscountPercentage,
      DiscountAmount: detail.DiscountAmount,
      Amount: detail.Amount,
      Id: detail.Id, // Include the detail Id in the mapped row for tracking
    }));

     // Convert date strings to DD-MM-YYYY format
 const convertDateForInput = (dateStr) => {
  if (typeof dateStr === 'string' && dateStr.includes('-')) {
      const [year, month, day] = dateStr.split(' ')[0].split('-');
      return `${year}-${month}-${day}`;
  } else {
      console.error('Invalid date format:', dateStr);
      return ''; // Return an empty string or handle it as needed
  }
};

    const purchasereturnDate = convertDateForInput(purchasereturn.PurchaseReturnDate.date);


  
    // Set the form fields
    setPurchaseReturnNo(purchasereturn.PurchaseReturnNo);
    setPurchaseReturnDate(purchasereturnDate);
    setRefrenceNo(purchasereturn.RefrenceNo);
    setSupplierId(purchasereturn.SupplierId);
    setAccountId(purchasereturn.AccountId);
    setSubTotal(purchasereturn.SubTotal);
    setExtra1(purchasereturn.Extra1);
    setExtra1Amount(purchasereturn.Extra1Amount);
    setExtra2(purchasereturn.Extra2);
    setExtra2Amount(purchasereturn.Extra2Amount);   
    setTotal(purchasereturn.Total);
    setTotalCopies(purchasereturn.TotalCopies);
    setRemark(purchasereturn.Remark);
  

    console.log(purchasereturn, 'purchase return');
    console.log(purchasereturndetail, 'purchase return detail')
  console.log(mappedRows, 'mapped rows')
    // Set the rows for the table with all the details
    setRows(mappedRows);
  
    // Set editing state
    setEditingIndex(row.index);
    setIsModalOpen(true);
    setIsEditing(true);
    setId(purchasereturn.Id);
  
    // Determine which specific detail to edit
    const specificDetail = purchasereturndetail.find(detail => detail.Id === row.original.Id);
    if (specificDetail) {
      setPurchasereturndetailId(specificDetail.Id); // Set the specific detail Id
    }

    fetchPurchasereturnDetails();
  };
  



  


  



  


  const handleDelete = (index) => {
    setPurchasereturns((prevPurchases) =>
      prevPurchases.filter((_, i) => i !== index)
    );
    toast.error('Purchase Return Deleted Successfully!');
  };



  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!PurchaseReturnNo) {
        formErrors.PurchaseReturnNo = "Purchase Return No  is required.";
        isValid = false;
    }
    if (!PurchaseReturnDate) {
        formErrors.PurchaseReturnDate = "Purchase Return Date is required.";
        isValid = false;
    }

    if (!RefrenceNo) {
      formErrors.RefrenceNo = "Reference No is required.";
      isValid = false;
  }
    if (!AccountId) {
        formErrors.AccountId = "Account Id is required.";
        isValid = false;
    }
    if (!SupplierId) {
      formErrors.SupplierId = "Supplier Id is required.";
      isValid = false;
  }


  if (!SubTotal) {
    formErrors.SubTotal = "Sub Total  is required.";
    isValid = false;
}
if (!Extra1) {
    formErrors.Extra1 = "Extra1 is required.";
    isValid = false;
}

if (!Extra1Amount) {
  formErrors.Extra1Amount = "Extra1 Amount is required.";
  isValid = false;
}
if (!Extra2) {
    formErrors.Extra2 = "Extra2 is required.";
    isValid = false;
}
if (!Extra2Amount) {
  formErrors.Extra2Amount = "Extra2 Amount is required.";
  isValid = false;
}

if (!Total) {
  formErrors.Total = "Total is required.";
  isValid = false;
}
if (!TotalCopies) {
    formErrors.TotalCopies = "Total Copies is required.";
    isValid = false;
}
if (!Remark) {
  formErrors.Remark = "Remark is required.";
  isValid = false;
}  

    setErrors(formErrors);
    return isValid;
};



const handleSubmit = async (e) => {
  e.preventDefault();
  if (!validateForm()) return;

  const formattedPurchaseReturnDate = moment(PurchaseReturnDate).format('YYYY-MM-DD');

  const purchasereturnData = {
    Id: isEditing ? id : '',  // Include the Id for updating, null for new records
    PurchaseReturnNo: PurchaseReturnNo,
    PurchaseReturnDate: formattedPurchaseReturnDate,
    RefrenceNo: RefrenceNo,
    SupplierId: SupplierId,
    AccountId: AccountId,
    SubTotal: SubTotal, 
    Extra1: Extra1, 
    Extra1Amount: Extra1Amount,
    Extra2: Extra2,
    Extra2Amount: Extra2Amount, 
    Total: Total, 
    TotalCopies: TotalCopies, 
    Remark: Remark, 
  };

  try {
    const purchasereturnUrl = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Purchasereturnheaderupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Purchasereturnheaderpost.php";

    // Submit purchase header data
    const response = await axios.post(purchasereturnUrl, qs.stringify(purchasereturnData), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    // const purchasereturnId = isEditing ? id : parseInt(response.data.newId, 10);
    const purchasereturnId = isEditing ? id : parseInt(response.data.Id, 10);

    for (const row of rows) {
      const rowData = {
        PurchaseReturnId: purchasereturnId,
        SerialNo: rows.indexOf(row) + 1,
        BookId: parseInt(row.BookId, 10),
        Copies: parseInt(row.Copies, 10),
        Rate: parseFloat(row.Rate),
        DiscountPercentage: parseFloat(row.DiscountPercentage),
        DiscountAmount: parseFloat(row.DiscountAmount),
        Amount: parseFloat(row.Amount),
        Id: row.Id,
      };

      // if (isEditing && row.Id) {
      //   // If editing, include PurchasedetailId for the update
      //   rowData.Id = row.PurchaseId; 
      // }

      const purchasereturndetailUrl = isEditing && row.Id
        ? "https://publication.microtechsolutions.co.in/php/Purchasereturndetailupdate.php"
        : "https://publication.microtechsolutions.co.in/php/Purchasereturndetailpost.php";

      await axios.post(purchasereturndetailUrl, qs.stringify(rowData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    }

    fetchPurchasereturns(); 
    fetchPurchasereturnDetails();
    setIsModalOpen(false);
    toast.success(isEditing ? 'Purchase return & Purchase Return Details updated successfully!' : 'Purchase return & Purchase Return Details added successfully!');
    resetForm(); // Reset the form fields after successful submission
  } catch (error) {
    console.error("Error saving record:", error);
    toast.error('Error saving record!');
  }
};

















 

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
     
      // {
      //   accessorKey: "PurchaseReturnNo",
      //   header: "Purchase Return No",
      //   size: 50,
      // },
      // {
      //   accessorKey: "PurchaseReturnDate.date",
      //   header: "Purchase Return Date",
      //   size: 50,
      //   Cell: ({ cell }) => {
      //     // Using moment.js to format the date
      //     const date = moment(cell.getValue()).format('DD-MM-YYYY');
      //     return <span>{date}</span>;
      //   },
      // },

      // {
      //   accessorKey: "Total",
      //   header: "Total",
      //   size: 50,
      // },
      // {
      //   accessorKey: "TotalCopies",
      //   header: "Total Copies",
      //   size: 50,
      // },
      // {
      //   accessorKey: "Remark",
      //   header: "Remark",
      //   size: 50,
      // },
     
     
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>
                          Edit

            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>


          </div>
        ),
      },
    ],
    [purchasereturns]
  );


  const table = useMaterialReactTable({
    columns,
    data: purchasereturns,
    muiTableHeadCellProps: {
      style: {
       backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

 
  return (
    <div className="convassordailyreport-container">
      <h1>
       Canvassor Daily Report
      </h1>

      <div className="convassordailyreporttable-master">
        <div className="convassordailyreporttable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", 
              fontWeight: "700", background:'#0a60bd', width:'15%' }}
            >
            New
          </Button>
          <div className="convassordailyreporttable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>



        {isModalOpen && <div className="convassordailyreport-overlay" onClick={() => setIsModalOpen(false)} />}

        <Modal open={isModalOpen}>
          <div className="convassordailyreport-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}>
              {editingIndex >= 0 ? "Edit Canvassor Daily Report"  : "Add Canvassor Daily Report"} 
            </h1>
            <form  className="convassordailyreport-form">
              

                <div>
                  <label className="convassordailyreport-label">Purchase Return No<b className="required">*</b>:</label>
                  <div>
                    <input
                    type="text"
                    id="PurchaseReturnNo"
                     name="PurchaseReturnNo" 
                    value={PurchaseReturnNo}
                    onChange={(e)=> setPurchaseReturnNo(e.target.value)}
                    className="convassordailyreport-control"
                    placeholder="Enter Purchase Return No"
                    />
                  </div>


                  <div>
                          {errors.PurchaseReturnNo && <b className="error-text">{errors.PurchaseReturnNo}</b>}
                        </div>
                </div>
                <div>
                  <label className="convassordailyreport-label">Purchase Return Date<b className="required">*</b>:</label>
                  <div>
                    <input
                    type="date"
                    id="PurchaseReturnDate"
                    name="PurchaseReturnDate" 
                    value={PurchaseReturnDate}
                    onChange={(e)=> setPurchaseReturnDate(e.target.value)}
                    className="convassordailyreport-control"
                    placeholder="Enter Purchase Return Date"
                    />
                  </div>

                  <div>
                          {errors.PurchaseReturnDate && <b className="error-text">{errors.PurchaseReturnDate}</b>}
                        </div>
                </div>

                <div>
                  <label className="convassordailyreport-label">Reference No<b className="required">*</b>:</label>
                  <div>
                    <input
                    type="text"
                    id="RefrenceNo"
                    name="RefrenceNo" 
                    value={RefrenceNo}
                    onChange={(e)=> setRefrenceNo(e.target.value)}
                    className="convassordailyreport-control"
                    placeholder="Enter Reference No"
                    />
                  </div>

                  <div>
                          {errors.RefrenceNo && <b className="error-text">{errors.RefrenceNo}</b>}
                        </div>
                </div>

                
                <div>
                  <label className="convassordailyreport-label">Supplier Id<b className="required">*</b>:</label>
                  <div>
                    
                  <Select
                   id="SupplierId"
                   name="SupplierId"
                   value={accountOptions.find((option) => option.value === SupplierId)}
                   onChange={(option) => setSupplierId(option.value)}
                   options={accountOptions} 
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                              marginTop: "10px",
                              borderRadius: "4px",
                              border: "1px solid rgb(223, 222, 222)",
                               marginBottom: '5px'
                     }),
                     menu: (base) => ({
                      ...base,
                      zIndex: 100,
                    }),
                   }}
                    placeholder="Select Supp id"
                  />  
                  </div>
                  <div>
                          {errors.SupplierId && <b className="error-text">{errors.SupplierId}</b>}
                        </div>
                </div>
                
               
                <div>
                  <label className="convassordailyreport-label"> Account Id<b className="required">*</b>:</label>
                  <div>
                  <Select
                   id="PurchaseAccountId"
                   name="PurchaseAccountId"
                   value={accountOptions.find((option) => option.value === AccountId)}
                   onChange={(option) => setAccountId(option.value)}
                   options={accountOptions} 
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                              marginTop: "10px",
                              borderRadius: "4px",
                              border: "1px solid rgb(223, 222, 222)",
                               marginBottom: '5px'
                     }),
                     menu: (base) => ({
                      ...base,
                      zIndex: 100,
                    }),
                   }}
                    placeholder="Select Acc id"
                  />  
                  </div>
                  <div>
                          {errors.AccountId && <b className="error-text">{errors.AccountId}</b>}
                        </div>
                </div>
</form>





    <div className="convassordailyreport-table">
  <table>
    <thead>
      <tr>
        <th>Serial No</th>
        <th>Book Name<b className="required">*</b></th>
        <th>Copies<b className="required">*</b></th>
        <th>Rate<b className="required">*</b></th>
        <th>Discount Percentage<b className="required">*</b></th>
        <th>Discount Amount<b className="required">*</b></th>
        <th>Amount<b className="required">*</b></th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
  {rows.map((row, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>
        <Select
          value={bookOptions.find((option) => option.value === row.BookId)}
          onChange={(option) => handleInputChange(index, 'BookId', option.value)}
          options={bookOptions}
          placeholder="Select Book"
          styles={{
            control: (base) => ({
              ...base,
              width: "150px",
            }),

            menu: (base) => ({
              ...base,
              zIndex: 100,
            }),
          }}
        />
      </td>
      <td>
        <input
          type="number"
          value={row.Copies}
          onChange={(e) => handleInputChange(index, 'Copies', e.target.value)}
          placeholder="Copies"
        />
      </td>
      <td>
        <input
          type="number"
          value={row.Rate}
          onChange={(e) => handleInputChange(index, 'Rate', e.target.value)}
          placeholder="Rate"
        />
      </td>
      <td>
        <input
          type="number"
          value={row.DiscountPercentage}
          onChange={(e) => handleInputChange(index, 'DiscountPercentage', e.target.value)}
          placeholder="Discount %"
        />
      </td>
      <td>
        <input
          type="number"
          value={row.DiscountAmount}
          readOnly
          placeholder="Discount Amount"
        />
      </td>
      <td>
        <input
          type="number"
          value={row.Amount}
          readOnly
          placeholder="Amount"
        />


      </td>
      <td>
<div style={{display:'flex', justifyContent:'space-between'}}>

      <Button
    onClick={handleAddRow}
    style={{ background: "#0a60bd", color: "white", marginRight: "5px" }}>
    Add 
  </Button>
        <Button
          onClick={() => handleDeleteRow(index)}
          style={{ background: "red", color: "white" }}>
          <RiDeleteBin5Line />
        </Button></div>

      </td>
    </tr>
  ))}
</tbody>

  </table>
  
</div>
<form className="convassordailyreport-form">
               

                <div>
                  <label className="convassordailyreport-label">Sub Total<b className="required">*</b>:</label>
                  <div>
                    <input
                    type="text"
                    id="SubTotal"
                     name="SubTotal"
                      value={SubTotal}                     onChange={(e)=> setSubTotal(e.target.value)}

                    className="convassordailyreport-control"
                    placeholder="Enter Sub Total"
                    />
                  </div>

                  <div>
                          {errors.SubTotal && <b className="error-text">{errors.SubTotal}</b>}
                        </div>
                </div>

                <div>
                  <label className="convassordailyreport-label">Extra1<b className="required">*</b>:</label>
                  <div>
                    <input
                    type="text"
                    id="Extra1"
                     name="Extra1"
                      value={Extra1}                     onChange={(e)=> setExtra1(e.target.value)}

                    className="convassordailyreport-control"
                    placeholder="Enter Extra1"
                    />
                  </div>

                  <div>
                          {errors.Extra1 && <b className="error-text">{errors.Extra1}</b>}
                        </div>
                </div>
                <div>
                  <label className="convassordailyreport-label">Extra1 Amount<b className="required">*</b>:</label>
                  <div>
                    <input
                    type="text"
                    id="Extra1Amount"
                     name="Extra1Amount"
                      value={Extra1Amount}                     onChange={(e)=> setExtra1Amount(e.target.value)}

                    className="convassordailyreport-control"
                    placeholder="Enter Extra1 Amount"
                    />
                  </div>
                  <div>
                          {errors.Extra1Amount && <b className="error-text">{errors.Extra1Amount}</b>}
                        </div>
                </div>
                <div>
                  <label className="convassordailyreport-label">Extra2<b className="required">*</b>:</label>
                  <div>
                    <input
                    type="text"
                    id="Extra2"
                     name="Extra2"
                      value={Extra2}                     onChange={(e)=> setExtra2(e.target.value)}

                    className="convassordailyreport-control"
                    placeholder="Enter Extra2"
                    />
                  </div>

                  <div>
                          {errors.Extra2 && <b className="error-text">{errors.Extra2}</b>}
                        </div>
                </div>
                <div>
                  <label className="convassordailyreport-label">Extra2 Amount<b className="required">*</b>:</label>
                  <div>
                    <input
                    type="text"
                    id="Extra2Amount"
                     name="Extra2Amount"
                      value={Extra2Amount}                     onChange={(e)=> setExtra2Amount(e.target.value)}

                    className="convassordailyreport-control"
                    placeholder="Enter Extra2 Amount"
                    />
                  </div>

                  <div>
                          {errors.Extra2Amount && <b className="error-text">{errors.Extra2Amount}</b>}
                        </div>
                </div>
                


               

                <div>
                  <label className="convassordailyreport-label">Total<b className="required">*</b>:</label>
                  <div>
                    <input
                    type="text"
                    id="Total"
                     name="Total"
                      value={Total}                     onChange={(e)=> setTotal(e.target.value)}

                    className="bookpurchase-control"
                    placeholder="Enter Total"
                    />
                  </div>

                  <div>
                          {errors.Total && <b className="error-text">{errors.Total}</b>}
                        </div>
                </div>


                <div>
                  <label className="bookpurchase-label">Total Copies<b className="required">*</b>:</label>
                  <div>
                    <input
                    type="text"
                    id="TotalCopies"
                     name="TotalCopies"
                      value={TotalCopies}
                      onChange={(e)=> setTotalCopies(e.target.value)}
                    className="bookpurchase-control"
                    placeholder="Enter Total Copies"
                    />
                  </div>
                  <div>
                          {errors.TotalCopies && <b className="error-text">{errors.TotalCopies}</b>}
                        </div>
                </div>


               

                <div>
                  <label className="bookpurchase-label">Remark<b className="required">*</b>:</label>
                <div>
                    <input
                    type="text"
                    id="Remark"
                     name="Remark"
                      value={Remark}
                      onChange={(e)=> setRemark(e.target.value)}
                    className="bookpurchase-control"
                    placeholder="Enter Remark"
                    />
                  
                </div>

                <div>
                          {errors.Remark && <b className="error-text">{errors.Remark}</b>}
                        </div>

                </div>


                </form>
                <div className="bookpurchase-btn-container">
                  <Button
                    type="submit" onClick={handleSubmit}
                    style={{
                      background: "#0a60bd",
                      color: "white",
                    }}>
                     {editingIndex >= 0 ? "Update" : "Save"}
                  </Button>
                  <Button
                    onClick={() => setIsModalOpen(false)}
                    style={{
                      background: "red",
                      color: "white",
                    }}>
                    Cancel
                  </Button>
                </div>
             
          </div>
        </Modal>
      </div>
      <ToastContainer/>
    </div>
  );
}
export default Convassordailyreport;
