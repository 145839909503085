import React from 'react'

function Ageinganalysis() {
  return (
    <div>
      Ageinganalysis
      Ageinganalysis
    </div>
  )
}

export default Ageinganalysis
