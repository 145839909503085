





import React, { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button, TextField } from "@mui/material";
import axios from "axios";
import "./BranchMaster.css";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
const BranchMaster = () => {
  const [BranchName, setBranchName] = useState("");
 
const [branchOptions, setBranchOptions] = useState([]);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [branches, setBranches] = useState([]);
 
  const [errors, setErrors] = useState('');
  const [id, setId] = useState('');

  
  useEffect(() => {
   
    fetchBranches();

  }, []);

  

  const fetchBranches = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Branchmasterget.php");
     
      setBranches(response.data);
    } catch (error) {
      toast.error("Error fetching Branches:", error);
    }
  };







  const handleNewClick = () => {
    resetFormFields("");
    setEditingIndex(-1);
    setIsModalOpen(true);
    setIsEditing(false)
  };

  const resetFormFields= ()=> {
    setBranchName('');
  

  }




  const handleEdit = (row) => {
    const branch = branches[row.index];
    setBranchName(branch.BranchName);
    
    setEditingIndex(row.index);
    setIsEditing(true);
    setIsModalOpen(true);
    setId(branch.Id); 

  };




  const handleDelete = (row) => {
    const newBranches = branches.filter((_, index) => index !== row.index);
    setBranches(newBranches);
    toast.error('Branch deleted successfully!');

  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!BranchName) {
        formErrors.BranchName = " Branch Name is required.";
        isValid = false;
    }


    
    setErrors(formErrors);
    return isValid;
};


 


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
      BranchName: BranchName,
      
    };
  
    // Determine the URL based on whether we're editing or adding
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Branchmasterupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Branchmasterpost.php";
  
    // If editing, include the author ID in the payload
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Branch updated successfully!');
      } else {
        toast.success('Branch added successfully!');
      }
      setIsModalOpen(false);
      resetFormFields();
      fetchBranches(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };


  

 

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "BranchName",
        header: " Branch Name",
        size: 50,
      },
     
      
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
           <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [branches]
  );

  const table = useMaterialReactTable({
    columns,
    data: branches,
    muiTableHeadCellProps: {
      style: {
         backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="branch-container">
      <h1>Branch Master</h1>
      <div className="branchtable-container">
        <div className="branchtable1-container">

          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", 
            fontWeight: "700", background:'#0a60bd', width:'15%' }}
>            New
          </Button>
          <div className="branchmastertable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>

        {isModalOpen && <div className="branch-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen}>
          <div className="branch-modal">
            <h2 style={{ textAlign: "center", fontWeight: 600 }}>
              {isEditing ? "Edit Branch" : "Add New Branch"}
            </h2>
            <form className="branch-form">
              <div>
                <label className="branch-label"> Branch Name<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="BranchName"
                    name="BranchName"
                    value={BranchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    className="branch-control"
                    placeholder="Enter Branch Name"
                  />                  
                  <div>
                  {errors.BranchName && <b className="error-text">{errors.BranchName}</b>}
                    </div>

                </div>
              </div>
             
             
            </form>

            <div className="branch-btn-container">
              <Button
                onClick={handleSubmit}
                style={{
                  background: "#0a60bd",
                  color: "white",
                }}>
         {editingIndex >= 0 ? "Update" : "Save"}

              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                style={{
                  background: "red",
                  color: "white",
                }}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <ToastContainer />

    </div>
  );
};

export default BranchMaster;
