import React from 'react'

function Booksofaccounts() {
  return (
    <div>
      Booksofaccounts
    </div>
  )
}

export default Booksofaccounts
