import React from 'react'

function Stock() {
  return (
    <div>
      Stock
      Stock
    </div>
  )
}

export default Stock
