
import React, { useState, useMemo, useEffect } from "react";
import "./Professor.css";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";

function Professors() {
  const [professors, setProfessors] = useState([]);
  const [ProfessorName, setProfessorName] = useState("");
  const [CategoryId, setCategoryId] = useState('');
  const [CategoryOptions, setCategoryOptions] = useState([]);
  const [AreaId, setAreaId] = useState("");
  const [CityId, setCityId] = useState("");
  const [StateId, setStateId] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [areaOptions, setareaOptions] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [CollegeId, setCollegeId] = useState('');
  // const [CategoryId, setCategoryId] = useState("");
  const [MobileNo, setMobileNo] = useState('');
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors ] = useState('');


  useEffect(() => {
    fetchProfessor();
    fetchStates(); 
    fetchAllCities();
    fetchColleges();
    fetchCategories();fetchAreas();
  }, []);

  const fetchProfessor = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Professorget.php");
      setProfessors(response.data);
    } catch (error) {
      toast.error("Error fetching Professors:", error);
    }
  };


  const fetchStates = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/State.php");
      const stateOptions = response.data.map((state) => ({
        value: state.Id,
        label: state.StateName,
      }));
      setStateOptions(stateOptions);
    } catch (error) {
      toast.error("Error fetching states:", error);
    }
  };




  const fetchAllCities = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Cityget.php");
      const cityOptions = response.data.map((city) => ({
        value: city.Id,
        label: city.CityName,
      }));
      setCityOptions(cityOptions);
    } catch (error) {
      toast.error("Error fetching cities:", error);
    }
  };


  const fetchAreas = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Areaget.php");
      const areaOptions = response.data.map((area) => ({
        value: area.Id,
        label: area.AreaName,
      }));
      setareaOptions(areaOptions);
    } catch (error) {
      toast.error("Error fetching areas:", error);
    }
  };

  const fetchColleges = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Collegeget.php");
      const colleges = response.data.map((college) => ({
        value: college.Id,
        label: college.CollegeName,
      }));
      setColleges(colleges);
    } catch (error) {
      toast.error("Error fetching colleges:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/ProfessorCategoryget.php");
      const CategoryOptions = response.data.map((cat) => ({
        value: cat.Id,
        label: cat.CategoryName,
      }));
      setCategoryOptions(CategoryOptions);
    } catch (error) {
      toast.error("Error fetching Categories:", error);
    }
  };

  

  const resetForm = () => {
    setProfessorName("");
    setCategoryId("");
    setCollegeId("");
    setAreaId("");
    setCityId("");
    setStateId("");
    setCategoryId("");
  };

  const handleNewClick = () => {
    resetForm();
    // setIsEditing(false);
    setEditingIndex(-1)
    setIsModalOpen(true);
  };

  const handleEdit = (row) => {
    const professor = professors[row.index];
    setProfessorName(professor.ProfessorName);
    setCollegeId(professor.CollegeId); // You might want to map this to a readable name
    setAreaId(professor.AreaId); // You might want to map this to a readable name
    setCityId(professor.CityId); // You might want to map this to a readable name
    setStateId(professor.StateId);
    setCategoryId(professor.Category); 
    setMobileNo(professor.MobileNo);// You might want to map this to a readable name
    setEditingIndex(row.index);
    setIsEditing(true)
    setIsModalOpen(true);
    setId(professor.Id);
  };

  const handleDelete = (index) => {
    setProfessors((prevProfessors) =>
      prevProfessors.filter((_, i) => i !== index)
    );
    toast.error('Professor Deleted Successfully!')
  };



  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!ProfessorName) {
        formErrors.ProfessorName = "Professor Name is required.";
        isValid = false;
    }

    if (!CategoryId) {
      formErrors.CategoryId = "Professor Category is required.";
      isValid = false;
  }

  if (!CollegeId) {
    formErrors.CollegeId = "College Name is required.";
    isValid = false;
}

    if (!AreaId) {
        formErrors.AreaId = "Area is required.";
        isValid = false;
    }

    if (!StateId) {
        formErrors.StateId = "State is required.";
        isValid = false;
    }

    if (!CityId) {
        formErrors.CityId = "City is required.";
        isValid = false;
    }

    

    if (!MobileNo) {
        formErrors.MobileNo = "Mobile No is required.";
        isValid = false;
    } else if (!/^\d{10}$/.test(MobileNo)) {
        formErrors.MobileNo = "Mobile No must be 10 digits.";
        isValid = false;
    }

    setErrors(formErrors);
    return isValid;
};

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
      ProfessorName: ProfessorName,
      CollegeId: CollegeId,
      Category: CategoryId, // Convert to integer
      AreaId: AreaId,
      CityId: CityId,
      StateId: StateId,
      MobileNo: MobileNo
      
    };
  
    // Determine the URL based on whether we're editing or adding
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Professorupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Professorpost.php";
  
    // If editing, include the author ID in the payload
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Professor updated successfully!');
      } else {
        toast.success('Professor added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchProfessor(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
     
      {
        accessorKey: "ProfessorName",
        header: "Professor Name",
        size: 50,
      },
     
      
      {
        accessorKey: "MobileNo",
        header: "Mobile No",
        size: 50,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
              <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [professors]
  );

  const table = useMaterialReactTable({
    columns,
    data: professors, muiTableHeadCellProps: {
      style: {
       backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="professor-container">
      <h1>Professor Master</h1>
      <div className="professortable-master">
        <div className="professortable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", 
            fontWeight: "700", background:'#0a60bd', width:'15%' }}
>            New
          </Button>
          <div className="proftable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>

        {isModalOpen && <div className="professor-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen}>
          <div className="professor-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}>
              {editingIndex >= 0 ? "Edit Professor" : "Add Professor"}
            </h1>
            <form  className="professor-form">

              <div>
                <label className="professor-label">Professor Name<b className="required">*</b></label>
                <div>
                  <input
                    id="ProfessorName"
                    name="ProfessorName"
                    value={ProfessorName}
                    onChange={(e) => setProfessorName(e.target.value)}
                    placeholder="Enter Professor Name"
                    className="professor-control"
                  />

<div>
                  {errors.ProfessorName && <b className="error-text">{errors.ProfessorName}</b>}
                    </div> 
                </div>
              </div>
              <div>
                <label className="professor-label">College Name<b className="required">*</b></label>
                <div>
                <Select
                    id="CollegeId"
                    name="CollegeId"
                    value={colleges.find((option) => option.value === CollegeId)}
                    onChange={(option) => setCollegeId(option.value)}
                    options={colleges}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                      }),
                    }}
                    placeholder="Select College "
                  />  
                  <div>
                  {errors.CollegeId && <b className="error-text">{errors.CollegeId}</b>}
                    </div>              
                </div>
              </div>
              <div>
                <label className="professor-label">Professor Category<b className="required">*</b></label>
               <div>
                <Select
                id="CategoryId"
                name="CategoryId"
                value={CategoryOptions.find((option) => option.value === CategoryId)}
                onChange={(option) => setCategoryId(option.value)}
                options={CategoryOptions}
                styles={{
                  control: (base) => ({
                    ...base,
                    width: "170px",
                    marginTop: "10px",
                    borderRadius: "4px",
                    border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                  }),
                }}
                placeholder="Select Category"
                />
                <div>
                  {errors.CategoryId && <b className="error-text">{errors.CategoryId}</b>}
                    </div>
                </div>
              </div>
              <div>
                <label className="professor-label">Area<b className="required">*</b></label>
                <div>
                <Select
                    id="AreaId"
                    name="AreaId"
                    value={areaOptions.find((option) => option.value === AreaId)}
                    onChange={(option) => setAreaId(option.value)}
                    options={areaOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                      }),
                    }}
                    placeholder="Enter Area"
                  />

<div>
                  {errors.AreaId && <b className="error-text">{errors.AreaId}</b>}
                    </div>
                </div>
              </div>
              <div>
                <label className="professor-label">State<b className="required">*</b></label>
                <div>
                  <Select
                    id="StateId"
                    name="StateId"
                    value={stateOptions.find((option) => option.value === StateId)}
                    onChange={(option) => setStateId(option.value)}
                    options={stateOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                      }),
                    }}
                    placeholder="Select State"
                  />                  

<div>
                  {errors.StateId && <b className="error-text">{errors.StateId}</b>}
                    </div>

                </div>
              </div>


              <div>
                <label className="professor-label">City<b className="required">*</b></label>
                <div>
                  <Select
                    id="CityId"
                    name="CityId"
                    value={cityOptions.find((option) => option.value === CityId)}
                    onChange={(option) => setCityId(option.value)}
                    options={cityOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                      }),
                    }}
                    placeholder="Select City"
                  />                 

<div>
                  {errors.CityId && <b className="error-text">{errors.CityId}</b>}
                    </div>

                </div>
              </div>

              <div>
                <label className="professor-label">Mobile No<b className="required">*</b></label>
                <div>
                  <input
                  type="text"

                  id="MobileNo"
                  name="MobileNo"
                  value={MobileNo}
                  onChange={(e)=> setMobileNo(e.target.value)}
                  className="professor-control"
                  placeholder="Enter Mobile Number"
                  />

<div>
                  {errors.MobileNo && <b className="error-text">{errors.MobileNo}</b>}
                    </div>
                </div>
              </div>
             
            </form>

            <div className="prof-btn-container">
                <Button
               onClick={handleSubmit}

                  style={{
                    background: "#0a60bd",
                    color: "white",
                  }}>
                {editingIndex >= 0 ? "Update" : "Save"}

                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}>
                  Cancel
                </Button>
              </div>
          </div>
        </Modal>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default Professors;
