import React, { useState, useMemo } from 'react';
import './Print.css';
import { useTable } from 'react-table';
import { Button } from "@mui/material";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function Print() {
  const [CompanyName, setCompanyName] = useState('');
  const [InvoiceNo, setInvoiceNo] = useState('');
  const [InvoiceDate, setInvoiceDate] = useState('');
  const [CustomerName, setCustomerName] = useState('');
  const [tableData, setTableData] = useState([
    // Sample data
    { product: 'Product 1', quantity: 2, amount: 100 },
    { product: 'Product 2', quantity: 1, amount: 50 },

    { product: 'Product 3', quantity: 2, amount: 100 },
    { product: 'Product 4', quantity: 1, amount: 50 },

   
  ]);

  const columns = useMemo(
    () => [
      {
        Header: 'Sr.No',
        accessor: (_, index) => index + 1,
      },
      {
        Header: 'Product',
        accessor: 'product',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
    ],
    []
  );

  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

//   const handlePrint = () => {
//     const input = document.querySelector('.printing-container');
//     html2canvas(input).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF();
//       const imgWidth = 190; // Adjust according to your content's width
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;
//       pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
//       pdf.save('invoice.pdf');
//     });
//   };


const handlePrint = () => {
    // Hide the print button and input borders before generating the PDF
    const printButton = document.querySelector('.print-button');
    const inputs = document.querySelectorAll('.companyprint-control');
  
    if (printButton) printButton.style.display = 'none';
    inputs.forEach(input => input.style.border = 'none');
  
    const input = document.querySelector('.printing-container');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = 190; // Adjust according to your content's width
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 20, 20, imgWidth, imgHeight);
      pdf.save('invoice.pdf');
  
      // Restore the print button and input borders after generating the PDF
      if (printButton) printButton.style.display = 'flex';
      inputs.forEach(input => input.style.border = '1px solid black');
    });
  };
  


// const handlePrint = () => {
//     const printButton = document.querySelector('.print-button');
//     const inputs = document.querySelectorAll('.companyprint-control');
  
//     if (printButton) printButton.style.display = 'none';
//     inputs.forEach(input => input.style.border = 'none');
  
//     const input = document.querySelector('.printing-container');
//     html2canvas(input, { scale: 12, }).then((canvas) => { 
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF();
//       const imgWidth = 190; 
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
//       pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
      
//       const pdfUrl = pdf.output('bloburl');
//       window.open(pdfUrl, '_blank');
  
//       if (printButton) printButton.style.display = 'flex';
//       inputs.forEach(input => input.style.border = '1px solid black');
//     });
//   };


  


  return (
    <div className='printing-container'>
      <h1>Company Details</h1>

      <div style={{marginTop:'20px', justifyContent:'center', display:'flex'}}>
        <div>
        <label className='print-label'>Company Name:</label>

       
        <div>
          <input
            type='text'
            id='CompanyName'
            name='CompanyName'
            value={CompanyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className='companyprint-control'
          />
        </div> </div>
      </div>

      <div className='invoice-print'>
        <div style={{marginRight:'250px'}}>
          <label className='print-label'>Invoice No:</label>
          <div>
            <input
              type='text'
              id='InvoiceNo'
              name='InvoiceNo'
              value={InvoiceNo}
              onChange={(e) => setInvoiceNo(e.target.value)}
              className='companyprint-control'
            />
          </div>
        </div>
        <div>
          <label className='print-label'>Invoice Date:</label>
          <div>
            <input
              type='date'
              id='InvoiceDate'
              name='InvoiceDate'
              value={InvoiceDate}
              onChange={(e) => setInvoiceDate(e.target.value)}
              className='companyprint-control'
            />
          </div>
        </div>
      </div>

      <div style={{marginTop:'20px', display:'flex', justifyContent:'center'}}>
        <div>
        <label className='print-label'>Customer Name:</label>

        <div><input
          type='text'
          id='CustomerName'
          name='CustomerName'
          value={CustomerName}
          onChange={(e) => setCustomerName(e.target.value)}
          className='companyprint-control'
        />        </div>


        </div>
        
      </div>

      <table {...getTableProps()} className='print-table-container'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className='print-button'>
        <Button onClick={handlePrint} style={{ color: 'white' }}>
          Print
        </Button>
      </div>
    </div>
  );
}

export default Print;




// import React from 'react'

// function Print() {
//   return (
//     <div>
//       <h1 style={{color: 'red'}}>Hello I Have to be Print!!!!</h1>
//     </div>
//   )
// }

// export default Print
