import React from 'react'

function Stockstmtdetails() {
  return (
    <div>
      Stockstmtdetails
      Stockstmtdetails
    </div>
  )
}

export default Stockstmtdetails
