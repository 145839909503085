




import React from "react";
import './Navbar.css'; // Add custom CSS for Navbar
import AccountCircle from '@mui/icons-material/AccountCircle';

const Navbar = () => {
  return (
    <nav className="navbar" >
      <div className="navbar-content">
        <h3 className="navbar-title">Welcome To Phadke Book Prakashan</h3> 
      </div>
    </nav>
  );
};

export default Navbar;
