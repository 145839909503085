import React, { useState, useMemo , useEffect} from "react";
import "./ProfCategory.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
function ProfCategory() {
  const [CategoryName, setCategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState("");
  const [errors, setErrors]  = useState('');

const fetchProfCategories = async ()=> {
  try {
    const response = await axios.get("https://publication.microtechsolutions.co.in/php/ProfessorCategoryget.php");
    setCategories(response.data);
  } catch (error) {
    toast.error("Error fetching Professor Categories:", error);
  }
}

useEffect(()=>{
  fetchProfCategories();
}, [])


  

  
  


  

  const resetForm = () => {
    setCategoryName('');
    setIsModalOpen(false);
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setEditingIndex(-1);
  };

  const handleEdit = (row) => {
    const category = categories[row.index];
    setCategoryName(category.CategoryName);
   
    setEditingIndex(row.index);
    setIsEditing(true);

    setIsModalOpen(true);
    setId(category.Id);
  };

  const handleDelete = (index) => {
    setCategories((preprofCats) => preprofCats.filter((_, i) => i !== index));
    toast.error('Category Deleted Successfully!')
  };


  const validateForm = ()=> {
    let formErrors = {};
    let isValid = true;

    
    if (!CategoryName) {
        formErrors.CategoryName = "Professor Category Name is required.";
        isValid = false;
    
    }

    
    setErrors(formErrors);
    return isValid;
  }


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
      CategoryName: CategoryName
      
    };
  
    // Determine the URL based on whether we're editing or adding
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/ProfessorCategoryupdate.php"
      : "https://publication.microtechsolutions.co.in/php/ProfessorCategorypost.php";
  
    // If editing, include the author ID in the payload
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Professor Category updated successfully!');
      } else {
        toast.success('Professor Category added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchProfCategories(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };

  const columns = useMemo(
    () => [

      
      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "CategoryName",
        header: "Prof Category Name",
        size: 50,
      },
      
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
               <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [categories]
  );

  const table = useMaterialReactTable({
    columns,
    data: categories, muiTableHeadCellProps: {
      style: {
          backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="partycat-container">
      <h1>Professor Category Master</h1>
      <div className="partycattable-master">
        <div className="partycattable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF",
             fontWeight: "700", background:'#0a60bd', width:'15%' }}
>            New
          </Button>
          <div className="partycattable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>


        {isModalOpen && <div className="patycat-overlay" onClick={() => setIsModalOpen(false)} />}

        <Modal open={isModalOpen}>
          <div className="partycat-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}>
              {editingIndex >= 0 ? "Edit Professor Category " : "Add Professor Category"}
            </h1>
            <form onSubmit={handleSubmit} className="partycat-form">

           
              <div>
                <label className="partycat-label">Professor Category Name<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="CategoryName"
                    name="CategoryName"
                    value={CategoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    className="partycat-control"
                    placeholder="Enter party category"
                  />

                  <div>
                  {errors.CategoryName && <b className="error-text">{errors.CategoryName}</b>}
                  </div>
                </div></div>
                </form>
              
             
              <div className="partycat-btn-container">
                <Button
                  type="submit" onClick={handleSubmit}
                  style={{
                    background: "#0a60bd",
                    alignContent: 'center',
                    color: "white",
                  }}>
               {editingIndex >= 0 ? "Update" : "Save"}

                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}>
                  Cancel
                </Button>
              </div>
          </div>
        </Modal>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default ProfCategory;
