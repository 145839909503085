import React from 'react'

function Backupcurrentcomp() {
  return (
    <div>
      Backupcurrentcomp
      Backupcurrentcomp
    </div>
  )
}

export default Backupcurrentcomp
