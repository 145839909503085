import React from 'react'

function Miscreports() {
  return (
    <div>
      Miscreports
    </div>
  )
}

export default Miscreports
