import React from 'react'

function Misreports() {
  return (
    <div>
      Misreports
      Misreports
    </div>
  )
}

export default Misreports
