import React from 'react'

function Stockstmt() {
  return (
    <div>
      Stockstmt
      Stockstmt
    </div>
  )
}

export default Stockstmt
