
import React, { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button, TextField } from "@mui/material";
import axios from "axios";
import "./college.css";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
const College = () => {
  const [CollegeName, setCollegeName] = useState("");
  const [CollegeCode, setCollegeCode] = useState("");
  const [EmailId, setEmailId] = useState("");
  const [Address1, setAddress1] = useState("");
  const [CityId, setCityId] = useState("");
  const [StateId, setStateId] = useState("");
  const [AreaId, setAreaId] = useState("");

  const [MobileNo, setMobileNo] = useState("");
  const [Pincode, setPincode] = useState("");
  const [FaxNo, setFaxNo] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [colleges, setColleges] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [areaOptions, setareaOptions] = useState([]);
  const [errors, setErrors] = useState('');
  const [id, setId] = useState('');

  
  useEffect(() => {
    fetchColleges();
    fetchAllCities();
    fetchStates();
    fetchAreas();


  }, []);

  const fetchColleges = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Collegeget.php");
      setColleges(response.data);
    } catch (error) {
      toast.error("Error fetching colleges:", error);
    }
  };




  const fetchStates = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/State.php");
      const stateOptions = response.data.map((state) => ({
        value: state.Id,
        label: state.StateName,
      }));
      setStateOptions(stateOptions);
    } catch (error) {
      toast.error("Error fetching states:", error);
    }
  };




  const fetchAllCities = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Cityget.php");
      const cityOptions = response.data.map((city) => ({
        value: city.Id,
        label: city.CityName,
      }));
      setCityOptions(cityOptions);
    } catch (error) {
      toast.error("Error fetching cities:", error);
    }
  };


  const fetchAreas = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Areaget.php");
      const areaOptions = response.data.map((area) => ({
        value: area.Id,
        label: area.AreaName,
      }));
      setareaOptions(areaOptions);
    } catch (error) {
      toast.error("Error fetching areas:", error);
    }
  };




  const handleNewClick = () => {
    resetFormFields();
    setIsEditing(false);
    setIsModalOpen(true);
  };




  const handleEdit = (row) => {
    const college = colleges[row.index];
    setCollegeName(college.CollegeName);
    setCollegeCode(college.CollegeCode);
    setEmailId(college.EmailId);
    setAreaId(college.AreaId || "");
    setAddress1(college.Address1 || "");
    setCityId(college.CityId || "");
    setStateId(college.StateId || "");
    setMobileNo(college.MobileNo || "");
    setPincode(college.Pincode || "");
    setFaxNo(college.FaxNo || "");
    setEditingIndex(row.index);
    setIsEditing(true);
    setIsModalOpen(true);
    setId(college.Id); // Set the author ID

  };




  const handleDelete = (row) => {
    const newColleges = colleges.filter((_, index) => index !== row.index);
    setColleges(newColleges);
    toast.error('College deleted successfully!');

  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    // College Name
    if (!CollegeName) {
        formErrors.CollegeName = "College Name is required.";
        isValid = false;
    }

    // College Code
    if (!CollegeCode) {
        formErrors.CollegeCode = "College Code is required.";
        isValid = false;
    }

    // Email ID
    if (!EmailId) {
        formErrors.EmailId = "Email Id is required.";
        isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(EmailId)) {
        formErrors.EmailId = "Email Id is invalid.";
        isValid = false;
    }

    // Address
    if (!Address1) {
        formErrors.Address1 = "Address is required.";
        isValid = false;
    }

    // Area
    if (!AreaId) {
        formErrors.AreaId = "Area is required.";
        isValid = false;
    }

    // State
    if (!StateId) {
        formErrors.StateId = "State is required.";
        isValid = false;
    }

    // City
    if (!CityId) {
        formErrors.CityId = "City is required.";
        isValid = false;
    }

    // Pincode
    if (!Pincode) {
        formErrors.Pincode = "Pincode is required.";
        isValid = false;
    } else if (!/^\d{6}$/.test(Pincode)) {
        formErrors.Pincode = "Pincode must be 6 digits.";
        isValid = false;
    }

    // Mobile No
    if (!MobileNo) {
        formErrors.MobileNo = "Mobile No is required.";
        isValid = false;
    } else if (!/^\d{10}$/.test(MobileNo)) {
        formErrors.MobileNo = "Mobile No must be 10 digits.";
        isValid = false;
    }

    // Fax No
    if (!FaxNo) {
        formErrors.FaxNo = "Fax No is required.";
        isValid = false;
    }

    setErrors(formErrors);
    return isValid;
};


 


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
      CollegeName: CollegeName,
      CollegeCode: CollegeCode,
      Address1: Address1,
      Address2: Address1,
      Address3: Address1,
      AreaId: AreaId,
      CityId: CityId,
      StateId: StateId,
      Pincode: Pincode,
      MobileNo: MobileNo,
      FaxNo: FaxNo,
      EmailId: EmailId
    };
  
    // Determine the URL based on whether we're editing or adding
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Collegeupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Collegepost.php";
  
    // If editing, include the author ID in the payload
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('College updated successfully!');
      } else {
        toast.success('College added successfully!');
      }
      setIsModalOpen(false);
      resetFormFields();
      fetchColleges(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };


  

  const resetFormFields = () => {
    setCollegeName("");
    setCollegeCode("");
    setEmailId("");
    setAreaId("");
    setAddress1("");
    setCityId("");
    setStateId("");
    setMobileNo("");
    setPincode("");
    setFaxNo("");
    setEditingIndex(-1);
  };

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "CollegeName",
        header: "College Name",
        size: 50,
      },
      {
        accessorKey: "CollegeCode",
        header: "Class Code",
        size: 50,
      },
      {
        accessorKey: "EmailId",
        header: "Email Id",
        size: 50,
      },
      {
        accessorKey: "Address1",
        header: "Address",
        size: 50,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
           <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [colleges]
  );

  const table = useMaterialReactTable({
    columns,
    data: colleges,
    muiTableHeadCellProps: {
      style: {
         backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="college-container">
      <h1>College Master</h1>
      <div className="collegetable-container">
        <div className="collegetable1-container">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", fontWeight: "700",
             background:'#0a60bd', width:'15%' }}
>            New
          </Button>
          <div className="colltable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>


        {isModalOpen && <div className="college-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen}>
          <div className="college-modal" >
            <h1 style={{ textAlign: "center", fontWeight: 500,  }}>
              {isEditing ? "Edit College" : "Add New College"}
            </h1>
            <form className="college-form">
              <div>
                <label className="college-label">College Name<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="CollegeName"
                    name="CollegeName"
                    value={CollegeName}
                    onChange={(e) => setCollegeName(e.target.value)}
                    className="college-control"
                    placeholder="Enter College Name"
                  />                  
                  <div className="error-text">
                  {errors.CollegeName && <b className="error-text">{errors.CollegeName}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="college-label">College Code<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="CollegeCode"
                    name="CollegeCode"
                    value={CollegeCode}
                    onChange={(e) => setCollegeCode(e.target.value)}
                    className="college-control"
                    placeholder="Enter College Code"
                  />                  <div>
                  {errors.CollegeCode && <b className="error-text">{errors.CollegeCode}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="college-label">Email Id<b className="required">*</b></label>
                <div>
                  <input
                    id="EmailId"
                    name="EmailId"
                    value={EmailId}
                    onChange={(e) => setEmailId(e.target.value)}
                    placeholder="Enter Email"
                    className="college-control"
                  />                  
                  <div>
                  {errors.EmailId && <b className="error-text">{errors.EmailId}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="college-label">Address<b className="required">*</b></label>
                <div>
                  <input
                    id="Address1"
                    name="Address1"
                    value={Address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    placeholder="Enter Address"
                    className="college-control"
                  />                 

<div>
                  {errors.Address1 && <b className="error-text">{errors.Address1}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="college-label">Area<b className="required">*</b></label>
                <div>
                  <Select
                   id="AreaId"
                   name="AreaId"
                   value={areaOptions.find((option) => option.value === AreaId)}
                   onChange={(option) => setAreaId(option.value)}
                   options={areaOptions} 
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)",
                        marginBottom: '5px'
                     }),
                   }}
                    placeholder="Select Area"
                  />                 
                  <div > 
                  {errors.AreaId && <b className="error-text">{errors.AreaId}</b>}
                    </div>
                </div>
              </div>
              <div>
                <label className="college-label">State<b className="required">*</b></label>
                <div>
                  <Select
                    id="StateId"
                    name="StateId"
                    value={stateOptions.find((option) => option.value === StateId)}
                    onChange={(option) => setStateId(option.value)}
                    options={stateOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                        marginTop: "10px",
                        borderRadius: "4px",
                        border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                      }),
                    }}
                    placeholder="Select State"
                  />                  

<div>
                  {errors.StateId && <b className="error-text">{errors.StateId}</b>}
                    </div>

                </div>
              </div>


              <div>
                <label className="college-label">City<b className="required">*</b></label>
                <div>
                  <Select
                    id="CityId"
                    name="CityId"
                    value={cityOptions.find((option) => option.value === CityId)}
                    onChange={(option) => setCityId(option.value)}
                    options={cityOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                        marginTop: "10px",
                        borderRadius: "4px",
                        border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                      }),
                    }}
                    placeholder="Select City"
                  />                 

<div>
                  {errors.CityId && <b className="error-text">{errors.CityId}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="college-label">Pincode<b className="required">*</b></label>
                <div>
                  <input
                    id="Pincode"
                    name="Pincode"
                    value={Pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    className="college-control"
                    placeholder="Enter Pincode"
                  />                  

<div>
                  {errors.Pincode && <b className="error-text">{errors.Pincode}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="college-label">Mobile No<b className="required">*</b></label>
                <div>
                  <input
                    id="MobileNo"
                    name="MobileNo"
                    value={MobileNo}
                    onChange={(e) => setMobileNo(e.target.value)}
                    className="college-control"
                    placeholder="Enter Tel No"
                  />                 

<div>
                  {errors.MobileNo && <b className="error-text">{errors.MobileNo}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="college-label">Fax No<b className="required">*</b></label>
                <div>
                  <input
                    id="FaxNo"
                    name="FaxNo"
                    value={FaxNo}
                    onChange={(e) => setFaxNo(e.target.value)}
                    className="college-control"
                    placeholder="Enter Fax No"
                  />                  

<div>
{errors.FaxNo && <b className="error-text">{errors.FaxNo}</b>}
</div>
                </div>
              </div>
            </form>

            <div className="clg-btn-container">
              <Button
                onClick={handleSubmit}
                style={{
                  background: "#0a60bd",
                  color: "white",
                }}>
             {editingIndex >= 0 ? "Update" : "Save"}

              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                style={{
                  background: "red",
                  color: "white",
                }}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <ToastContainer />

    </div>
  );
};

export default College;
