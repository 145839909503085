





import React, { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button, TextField } from "@mui/material";
import axios from "axios";
import "./User.css";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
const User = () => {
  const [Name, setName] = useState("");
  const [UserId, setUserId] = useState("");
  const [Password, setPassword] = useState("");
  const [LevelId, setLevelId] = useState("");
  const [LoginStatus, setLoginStatus] = useState("");
  const [BranchId, setBranchId] = useState("");
  const [CompanyId, setCompanyId] = useState("");
const [levelOptions, setLevelOptions] = useState([]);
const [branchOptions, setBranchOptions] = useState([]);
const [companyOptions, setCompanyOptions] = useState([]);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [users, setUsers] = useState([]);
 
  const [errors, setErrors] = useState('');
  const [id, setId] = useState('');

  
  useEffect(() => {
    fetchUsers();
    fetchCompanies();
    fetchLevels();
    fetchBranches();

  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Userget.php");
      setUsers(response.data);
    } catch (error) {
      toast.error("Error fetching users:", error);
    }
  };



  const fetchCompanies = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/CompanyMasterget.php");
      const companyOptions = response.data.map((comp) => ({
        value: comp.Id,
        label: comp.CompanyName,
      }));
      setCompanyOptions(companyOptions);
    } catch (error) {
      toast.error("Error fetching Companies:", error);
    }
  };


  const fetchLevels = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Levelmasterget.php");
      const levelOptions = response.data.map((level) => ({
        value: level.Id,
        label: level.LevelName,
      }));
      setLevelOptions(levelOptions);
    } catch (error) {
      toast.error("Error fetching Levels:", error);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Branchmasterget.php");
      const branchOptions = response.data.map((branch) => ({
        value: branch.Id,
        label: branch.BranchName,
      }));
      setBranchOptions(branchOptions);
    } catch (error) {
      toast.error("Error fetching Branches:", error);
    }
  };







  const handleNewClick = () => {
    resetFormFields();
    setIsEditing(false);
    setEditingIndex(-1)
    setIsModalOpen(true);
  };

  const resetFormFields= ()=> {
    setName('');
    setUserId('');
    setPassword('');
    setLevelId('');
    setLoginStatus('');
    setBranchId('');
    setCompanyId('');

  }




  const handleEdit = (row) => {
    const user = users[row.index];
    setName(user.Name);
    setUserId(user.UserId);
    setPassword(user.Password);
    setLevelId(user.LevelId);
    setLoginStatus(user.LoginStatus);
    setBranchId(user.BranchId);
    setCompanyId(user.CompanyId);
    setEditingIndex(row.index);
    setIsEditing(true);
    setIsModalOpen(true);
    setId(user.Id); // Set the author ID

  };




  const handleDelete = (row) => {
    const newUsers = users.filter((_, index) => index !== row.index);
    setUsers(newUsers);
    toast.error('User deleted successfully!');

  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!Name) {
        formErrors.Name = " Name is required.";
        isValid = false;
    }

    if (!UserId) {
        formErrors.UserId = "User id is required.";
        isValid = false;
    }

    if (!Password) {
      formErrors.Password = "Password is required.";
      isValid = false;
  }

  
  if (!LevelId) {
    formErrors.LevelId = "Level id is required.";
    isValid = false;
}




if (!BranchId) {
  formErrors.BranchId = "Branch Id is required.";
  isValid = false;
}

if (!CompanyId) {
  formErrors.CompanyId = "Company Id is required.";
  isValid = false;
}

    
    setErrors(formErrors);
    return isValid;
};


 


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
      Name: Name,
      UserId: UserId,
      Password : Password,
      LevelId : LevelId,
       LoginStatus : LoginStatus,
        BranchId : BranchId,
         CompanyId: CompanyId
    };
  
    // Determine the URL based on whether we're editing or adding
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Userupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Userpost.php";
  
    // If editing, include the author ID in the payload
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('User updated successfully!');
      } else {
        toast.success('User added successfully!');
      }
      setIsModalOpen(false);
      resetFormFields();
      fetchUsers(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };


  

 

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "Name",
        header: " Name",
        size: 50,
      },
      {
        accessorKey: "UserId",
        header: "User Id",
        size: 50,
      },
      {
        accessorKey: "LoginStatus",
        header: "Login Status",
        size: 50,
      },
      
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [users]
  );

  const table = useMaterialReactTable({
    columns,
    data: users,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="user-container">
      <h1>User Master</h1>

      <div className="usertable-container">
        <div className="usertable1-container">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF",
             fontWeight: "700", background:'#0a60bd', width:'15%' }}
>            New
          </Button>
          <div className="usetable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>

        {isModalOpen && <div className="user-overlay" onClick={() => setIsModalOpen(false)} />}


         <Modal open={isModalOpen}>
          <div className="user-modal">
            <h1 style={{ textAlign: "center", fontWeight: 600 }}>
              {isEditing ? "Edit User" : "Add New User"}
            </h1>
            <form className="user-form">
              <div>
                <label className="user-label"> Name<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="Name"
                    name="Name"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    className="user-control"
                    placeholder="Enter Name"
                  />                  
                  <div>
                  {errors.Name && <b className="error-text">{errors.Name}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="user-label">User Id<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="UserId"
                    name="UserId"
                    value={UserId}
                    onChange={(e) => setUserId(e.target.value)}
                    className="user-control"
                    placeholder="Enter user id"
                  />         
                           <div>
                  {errors.UserId && <b className="error-text">{errors.UserId}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="user-label">Password<b className="required">*</b></label>
                <div>
                  <input
                    id="Password"
                    name="Password"
                    value={Password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Password"
                    className="user-control"
                  />                  
                  <div>
                  {errors.Password && <b className="error-text">{errors.Password}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="user-label">Level Id<b className="required">*</b></label>
                <div>
                <Select
                   id="LevelId"
                   name="LevelId"
                   value={levelOptions.find((option) => option.value === LevelId)}
                   onChange={(option) => setLevelId(option.value)}
                   options={levelOptions} 
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                              marginTop: "10px",
                              borderRadius: "4px",
                              border: "1px solid rgb(223, 222, 222)",
                               marginBottom: '5px'
                     }),
                   }}
                    placeholder="Select Level"
                  />                      

<div>
                  {errors.LevelId && <b className="error-text">{errors.LevelId}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="user-label">Login Status</label>
                {/* <div> */}
                  <input
                  type="checkbox"
                   id="LoginStatus"
                   name="LoginStatus"
                   checked={LoginStatus}
                   onChange={(e) => setLoginStatus(e.target.checked)}
                 // className="user-control" 
                 style={{marginLeft:'10px'}}
                    placeholder="Select Login Status"
                  />                 
                  <div > 
                  {errors.LoginStatus && <b className="error-text">{errors.LoginStatus}</b>}
                    </div>
                {/* </div> */}
              </div>
              <div>
                <label className="user-label">Branch Id<b className="required">*</b></label>
                <div>
                  <Select
                    id="BranchId"
                    name="BranchId"
                    value={branchOptions.find((option) => option.value === BranchId)}
                    onChange={(option) => setBranchId(option.value)}
                    options={branchOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                              marginTop: "10px",
                              borderRadius: "4px",
                              border: "1px solid rgb(223, 222, 222)",
                               marginBottom: '5px'
                      }),
                    }}
                    placeholder="Select Branch"
                  />                  

<div>
                  {errors.BranchId && <b className="error-text">{errors.BranchId}</b>}
                    </div>

                </div>
              </div>


              <div>
                <label className="user-label">Company Id<b className="required">*</b></label>
                <div>
                  <Select
                    id="CompanyId"
                    name="CompanyId"
                    value={companyOptions.find((option) => option.value === CompanyId)}
                    onChange={(option) => setCompanyId(option.value)}
                    options={companyOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                              marginTop: "10px",
                              borderRadius: "4px",
                              border: "1px solid rgb(223, 222, 222)",
                               marginBottom: '5px'
                      }),
                    }}
                    placeholder="Select Company"
                  />                 

<div>
                  {errors.CompanyId && <b className="error-text">{errors.CompanyId}</b>}
                    </div>

                </div>
              </div>
             
            </form>

            <div className="user-btn-container">
              <Button
                onClick={handleSubmit}
                style={{
                  background: "#0a60bd",
                  color: "white",
                }}>
         {editingIndex >= 0 ? "Update" : "Save"}

              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                style={{
                  background: "red",
                  color: "white",
                }}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <ToastContainer />

    </div>
  );
};

export default User;
