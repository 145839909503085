import React from 'react'

function Royalty() {
  return (
    <div>
      Royalty
    </div>
  )
}

export default Royalty
