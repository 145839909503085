//SmallComponent.js

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import Grid from "@material-ui/core/Grid";
import MoreVertIcon from "@material-ui/icons/MoreVert";

// Import your profile image in images folder
import myImg from "../components/book.jpg";

// Import your background image in images folder
import backImg2 from "../components/book.jpg";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({

	// Styling material components
	root: {
		width: "100%",
		height: "100vh",
		backgroundColor: theme.palette.background.default,
		[theme.breakpoints.down("xs")]: {
			paddingTop: theme.spacing(2),
		},
	},
	media: {
		height: 56,
		paddingTop: "56.25%", // 16:9
	},
	avatar: {
		backgroundColor: red[500],
	},
}));

export default function SmallComponent({
	toggleDark, settoggleDark }) {
	const classes = useStyles();

	// Trigger toggle using onChange Switch
	const handleModeChange = () => {
		settoggleDark(!toggleDark);
	};
	return (
		<Grid
			className={classes.root}
			container
			justify="center"
			alignItems="center"
		>
			<Card elevation={8}>
				{/* you can modify the image avatar,
		background and title name to yours*/}
				<CardHeader
					avatar={
						<Avatar alt="Nikita Chaudhari"
							src={myImg} />
					}
					action={
						<IconButton aria-label="settings">
							<MoreVertIcon />
						</IconButton>
					}
					title="Nikita Pradip Chaudhari"
					subheader="nikita12c"
				/>
				<CardMedia
					className={classes.media}
					image={backImg2}
					title="Paella dish"
				/>
				<CardContent>
					<Typography variant="body2"
						color="textSecondary"
						component="p">
						Geeks For Geeks : Dark Mode
					</Typography>
				</CardContent>
				<CardActions disableSpacing>
					<IconButton
						aria-label="add to favorites">
						<FavoriteIcon />
					</IconButton>
					<IconButton aria-label="share">
						<ShareIcon />
					</IconButton>
					{/* switching between dark and light mode */}
					<Switch
						checked={toggleDark}
						onChange={handleModeChange}
						name="toggleDark"
						color="default"
					/>
				</CardActions>
			</Card>
		</Grid>
	);
}
