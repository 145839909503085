import React from 'react'

function Listing() {
  return (
    <div>
      Listing
      Listing
    </div>
  )
}

export default Listing
