import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./State.css";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Modal, Button } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";


function City() {
  const [CityName, setCityName] = useState('');
  const [convassor, setConvassor] = useState("");
  const [cities, setCities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [error, setError] = useState('');
  const [id, setId] = useState('');
  const [isEditing, setIsEditing] = useState(false)
  const [errors, setErrors] = useState('');

  const fetchAllCities = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Cityget.php");
      setCities(response.data);
    } catch (error) {
      toast.error("Error fetching cities:", error);
    }
  };

  useEffect(() => {
    fetchAllCities();
  }, []);

  const resetForm = () => {
    setCityName("");
    setConvassor("");
    setIsModalOpen(false);
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setEditingIndex(-1);
  };

  const handleEdit = (row) => {
    const city = cities[row.index];
    setCityName(city.CityName);
    // setConvassor(city.convassor || ""); // Ensure convassor is correctly set
    setEditingIndex(row.index);
    setIsModalOpen(true);
    setId(city.Id);
    setIsEditing(true)
  };

  const handleDelete = (index) => {
    const newCities = cities.filter((_, i) => i !== index);
    setCities(newCities);
    toast.success('City Deleted Successfully!');
  };

  


  const validateForm = ()=> {
    let formErrors = {};
    let isValid = true;
    if (!CityName) {
        formErrors.CityName = "City Name is required.";
        isValid = false;
    }
    setErrors(formErrors);
    return isValid;
  }
  
  

 



  const handleSubmit = async (e) => {
    e.preventDefault(); 
    if (!validateForm()) return;
  
    const data = {
      CityName: CityName,
    };
  
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Cityupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Citypost.php";
  
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('City updated successfully!');
      } else {
        toast.success('City added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchAllCities(); 
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
  

  const removeDuplicateEntries = () => {
    const uniqueCities = cities.filter((city, index, self) =>
      index === self.findIndex((c) => c.CityName === city.CityName)
    );
    if (uniqueCities.length === cities.length) {
      toast.info('No Duplicate Cities found');
    } else {
      setCities(uniqueCities);
      toast.success('Duplicate Cities removed');
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "CityName",
        header: "City",
        size: 50,
      },
      
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
              <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [cities]
  );

  const convassorOptions = [
    { value: "Convassor1", label: "Convassor1" },
    { value: "Convassor2", label: "Convassor2" },
  ];

  const table = useMaterialReactTable({
    columns,
    data: cities, muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="state-container">
      <h1>City Master</h1>
      <div className="statetable-master">
        <div className="statetable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF",
               fontWeight: "700", background:'#0a60bd', width:'15%' }}
          >
            New
          </Button>
          <Button
            onClick={removeDuplicateEntries}
            style={{ color: "orange",background:'#0a60bd', fontWeight: "700", marginLeft: "10px" }}
          >
            Remove Duplicate Entry
          </Button>
          <div className="statetable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>
        {isModalOpen && <div className="state-overlay" onClick={() => setIsModalOpen(false)} />}

        <Modal open={isModalOpen}>
          <div className="state-modal">
            <h2
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}
            >
              {editingIndex >= 0 ? "Edit City " : "Add New City "}
            </h2>

            <form className="state-form">
              <div>
                <label className="state-label">City<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="CityName"
                    name="CityName"
                    value={CityName}
                    onChange={(e) => setCityName(e.target.value)}
                    className="state-control"
                    placeholder="Enter City Name"
                  />
                  <div>
                  {errors.CityName && <b className="error-text">{errors.CityName}</b>}

                  </div>
                </div>
              </div>
              {/* <div>
                <label className="state-label">Select Convassor:</label>
                <div>
                  <Select
                    id="convassor"
                    name="convassor"
                    value={convassorOptions.find((option) => option.value === convassor) || null}
                    onChange={(option) => setConvassor(option.value)}
                    options={convassorOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "180px",
                        marginTop: "10px",
                        borderRadius: "8px",
                        border: "1px solid black",
                      }),
                    }}
                    placeholder="Select Convassor"
                  />
                </div>
              </div> */}
            </form>

            <div className="state-btn-container">
              <Button
                type="submit"
                style={{
                  background: "#0a60bd",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                {editingIndex >= 0 ? "Update" : "Save"}
              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                style={{
                  background: "red",
                  color: "white",
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
}

export default City;
