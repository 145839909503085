




import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./State.css";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Modal, Button } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";


function Country() {
  
  const [convassor, setConvassor] = useState("");
  const [CountryName, setCountryName] = useState('');
  const [countries, setCountries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [id, setId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors ] = useState('')
  
  useEffect(()=>{
    fetchCountries();
   },[])


  const fetchCountries = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Countryget.php");
      
      setCountries(response.data);
    } catch (error) {
      toast.error("Error fetching countries:", error);
    }
  };
 
 
 

  
  const resetForm = () => {
    setCountryName("");
   
    setConvassor("");
    setIsModalOpen(false);
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setEditingIndex(-1);
  };



  const handleEdit = (row) => {
    const country = countries[row.index];
    setCountryName(country.CountryName);
    setEditingIndex(row.index);
    setId(country.Id);
    setIsEditing(true);
    setIsModalOpen(true);
  };
  

  const handleDelete = (index) => {
    const newCountries = countries.filter((_, i) => i !== index);
    setCountries(newCountries);
    toast.error("Country Deleted Successfully!")
  };

  const validateForm = ()=> {
    let formErrors = {};
    let isValid = true;

    
    if (!CountryName) {
        formErrors.CountryName = "Country Name is required.";
        isValid = false;
    
    }

    
    setErrors(formErrors);
    return isValid;
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
      CountryName : CountryName
    };
  
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Countryupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Countrypost.php";
  
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Country updated successfully!');
      } else {
        toast.success('Country added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchCountries(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
  

 

  


  const removeDuplicateEntries = () => {
    const uniqueCountries = countries.filter((country, index, self) =>
      index === self.findIndex((c) => c.CountryName === country.CountryName)
    );
    if (uniqueCountries.length === countries.length) {
      toast.info('No Duplicate Countries found');
    } else {
      setCountries(uniqueCountries);
      toast.success('Duplicate Countries removed');
    }
  };

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "CountryName",
        header: "Country",
        size: 50,
      },
      
      
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
             <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [countries]
  );

  


  const table = useMaterialReactTable({
    columns,
    data: countries, muiTableHeadCellProps: {
      style: {
          backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="state-container">
      <h1>Country Master</h1>
      <div className="statetable-master">
        <div className="statetable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", 
              fontWeight: "700", background:'#0a60bd', width:'15%' }}
          >
            New
          </Button>
          <Button
            onClick={removeDuplicateEntries}
            style={{ color: "orange",
               fontWeight: "700", 
               marginLeft: "10px", background:'#0a60bd' }}
          >
            Remove Duplicate Entry
          </Button>
          <div className="statetable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>


        {isModalOpen && <div className="state-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen} >
          <div className="state-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}
            >
              {editingIndex >= 0 ? "Edit Country" : "Add New Country"}
            </h1>

           

            <form className="state-form">
                <div>
                  <label className="state-label"> Country<b className="required">*</b></label>
                  <div>
                   

                    <input
                    type="text" 
                    id="CountryName" name="CountryName" value={CountryName} 
                    onChange={(e)=> setCountryName(e.target.value)} 
                    className="state-control" 
                    placeholder="Enter Country Name"
                    />         
                    <div>
                    {errors.CountryName && <b className="error-text">{errors.CountryName}</b>}
                      </div>        

                  </div>
                </div>
              

{/* <div> 
<label className="state-label"> Convassor:</label>
              <div>
              <Select
  id="convassor"
  name="convassor"
  value={convassorOptions.find((option) => option.value === convassor)}
  onChange={(option) => setConvassor(option.value)}
  options={convassorOptions}
  styles={{
    control: (base) => ({
      ...base,
      width: "180px",
      marginTop: "10px",
      borderRadius: "8px",
      border: "1px solid black",
    }),
  }}
  placeholder="Select Convassor"
/>

              </div>
</div> */}
</form>


              <div className="state-btn-container">
                <Button
                  type="submit"
                  style={{
                    background: "#0a60bd",
                    color: "white",
                  }}
                  onClick={handleSubmit}
                >
                                  {editingIndex >= 0 ? "Update" : "Save"}

                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
              </div>
          </div>
        </Modal>
      </div>
      <ToastContainer />

    </div>
  );
}

export default Country;
