import React from 'react'

function Convassingcollegelist() {
  return (
    <div>
      Convassingcollegelist
      Convassingcollegelist
    </div>
  )
}

export default Convassingcollegelist
