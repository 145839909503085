import React from 'react'

function Stockbook() {
  return (
    <div>
      Stockbook
      Stockbook
    </div>
  )
}

export default Stockbook
