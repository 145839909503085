import React, { useState, useMemo, useEffect } from "react";
import "./DepositorGroup.css";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button, TextField } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";


function DepositerGroup() {
  const [DepositerGroupName, setDepositerGroupName] = useState("");
  const [depositerGroups, setDepositerGroups] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId]  = useState("");

  const [errors, setErrors] = useState('');

  

  const fetchDepositerGroups = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/DepositerGroupget.php");
      setDepositerGroups(response.data);
    } catch (error) {
      toast.error("Error fetching Depositor groups:", error);
    }
  };


  useEffect(() => {
    fetchDepositerGroups();
  }, []);



  const handleNewClick = () => {
    resetForm();
    setIsEditing(false);
    setEditingIndex(-1)
    setIsModalOpen(true);

  };

  const resetForm = ()=>{
    setDepositerGroupName("");
  }


  const handleEdit = (row) => {
    const depo = depositerGroups[row.index];
    setDepositerGroupName(depo.DepositerGroupName);
    setEditingIndex(row.index);
    setIsEditing(true)
    setIsModalOpen(true);
    setId(depo.Id);
  };

 
  
  const handleDelete = (index) => {
    setDepositerGroups((prevDepositerGroups) =>
      prevDepositerGroups.filter((_, i) => i !== index)
    );
    toast.error("Depositer Group Deleted Successfully!")
  };


  const validateForm = ()=> {
    let formErrors = {};
    let isValid = true;
    if (!DepositerGroupName) {
        formErrors.DepositerGroupName = "Depositer Group Name is required.";
        isValid = false;
    }
    setErrors(formErrors);
    return isValid;
  }


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
     DepositerGroupName: DepositerGroupName
      
    };
  
    // Determine the URL based on whether we're editing or adding
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/DepositerGroupupdate.php"
      : "https://publication.microtechsolutions.co.in/php/DepositerGrouppost.php";
  
    // If editing, include the author ID in the payload
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Depositer Group updated successfully!');
      } else {
        toast.success('Depositer Group added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchDepositerGroups(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
     
    
      {
        accessorKey: "DepositerGroupName",
        header: "Depositer Name",
        size: 50,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
           <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [depositerGroups]
  );

  const table = useMaterialReactTable({
    columns,
    data: depositerGroups, muiTableHeadCellProps: {
      style: {
         backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="depositor-container">
      <h1>
        Depositer Group Master
      </h1>
      <div className="depotable-master">
        <div className="depotable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF",
               fontWeight: "700", background:'#0a60bd', width:'15%' }}
          >
            New
          </Button>
          <div className="depotable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>

        {isModalOpen && <div className="depositor-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="depositor-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}
            >
              {isEditing ? "Edit Depositor Group" : "New Depositor Group"}
              </h1>
            <form onSubmit={handleSubmit} className="depomaster-form">
             
              <div>
                <label className="depositor-label">Depositor Name<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="DepositerGroupName" 
                    name="DepositerGroupName"
                    value={DepositerGroupName}
                    onChange={(e) => setDepositerGroupName(e.target.value)}
                    placeholder="Enter DepositerGroup Name"
                    className="depo-control"
                    
                  />

                  <div>
                    {errors.DepositerGroupName && <b className="error-text">{errors.DepositerGroupName}</b>}

                  </div>
                </div>
              </div></form>
              <div className="depo-btn-container">
                <Button
                  onClick={handleSubmit}
                  style={{
                    background: "#0a60bd",
                    color: "white",
                  }}
                >
        {editingIndex >= 0 ? "Update" : "Save"}

                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
              </div>
            
          </div>
        </Modal>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default DepositerGroup;
