import React, { useState, useMemo, useEffect } from "react";
import './Salestoconvassor.css';
import Select from 'react-select';
import axios from "axios";
import { Button, TextField, Modal } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
import moment from 'moment';
import qs from 'qs';

function Salestoconvassor() {
 const [InvoiceNo, setInvoiceNo] = useState('');
 const [InvoiceDate, setInvoiceDate] = useState('');
 const [BillType, setBillType] = useState('');
 
 const [AccountId, setAccountId] = useState('');
 const [ReceiptNo, setReceiptNo] = useState('');
 const [ReceiptDate, setReceiptDate] = useState('');
 const [Wait, setWait] = useState('');

 const [Bundles, setBundles] = useState('');
 const [Freight, setFreight] = useState('');
 const [DispatchModeId, setDispatchModeId] = useState('');
 const [PaymentMode, setPaymentMode] = useState('');
 const [OrderNo, setOrderNo] = useState('');
 const [OrderDate, setOrderDate] = useState('');
 const [ReceivedOn, setReceivedOn] = useState('');
 const [ReceivedThrough, setReceivedThrough] = useState('');

 const [CanvassorId, setCanvassorId] = useState('');
 const [TotalCopies, setTotalCopies] = useState('');
 const [SubTotal, setSubTotal] = useState('');
 
 const [Total, setTotal] = useState('');
 const [Remark, setRemark] = useState(''); 
 const [IsPaperPurchase, setIsPaperPurchase] = useState('false');

  const [editingIndex, setEditingIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState('');

  const [sellsdetailId, setSellsdetailId] = useState('');
const [errors, setErrors] = useState('');
const [isEditing, setIsEditing] = useState(false);
const [sellsforcanvassors, setSellsforcanvassors] = useState([]);
const [sellsforcanvassordetails, setSellsforcanvassordetails] = useState([]);




//Dropdown for ID's
const [bookOptions, setBookOptions] = useState([]);
const [accountOptions, setAccountOptions] = useState([]);
const [canvassorOptions, setCanvassorOptions] = useState([]);
const [dispatchmodeOptions, setDispatchmodeOptions] = useState([]);

const [rows, setRows] = useState([
  {
    BookId: '', // Default value for the first row
    Copies: 0,
    Rate: 0,
    DiscountPercentage: 0,
    DiscountAmount: 0,
    Amount: 0,
  },
]);



  useEffect(()=>{
    fetchSellsforcanvassor();
    fetchSellsdetailsforcanvassor();
    fetchBooks();
    fetchAccounts();
    fetchDispatchmodes();
    fetchAssigncanvassors();
  }, []);

  const fetchSellsforcanvassor = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Sellsheaderget.php");
      setSellsforcanvassors(response.data);
    } catch (error) {
      toast.error("Error fetching Sells :", error);
    }
  };

  // Fetch the purchase details
  const fetchSellsdetailsforcanvassor = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Sellsdetailget.php");
      // console.log(response.data, 'response of purchase return details')
      setSellsforcanvassordetails(response.data);
    } catch (error) {
      toast.error("Error fetching Sells details:", error);
    }
  };


  // Calculation functions
  const calculateTotals = () => {
    let subtotal = 0;
    let totalCopies = 0;
    let total = 0;

    rows.forEach(row => {
      totalCopies += Number(row.Copies) || 0;
      total += Number(row.Amount) || 0;
      subtotal += Number(row.DiscountAmount) || 0;
    });
    setTotalCopies(totalCopies);
    setTotal(total);
    setSubTotal(subtotal);
  };




    
  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    
    // Update the value of the current field
    updatedRows[index][field] = value;
  
    // Calculate the Amount when Copies and Rate are entered
    if (field === 'Copies' || field === 'Rate') {
      const copies = updatedRows[index].Copies || 0;
      const rate = updatedRows[index].Rate || 0;
      updatedRows[index].Amount = copies * rate;
    }
  
    // Calculate the DiscountAmount and FinalAmount when DiscountPercentage is entered
    if (field === 'DiscountPercentage' || field === 'Copies' || field === 'Rate') {
      const discountPercentage = updatedRows[index].DiscountPercentage || 0;
      const amount = updatedRows[index].Amount || 0;
      updatedRows[index].DiscountAmount = (amount * discountPercentage) / 100;
      updatedRows[index].Amount = amount - updatedRows[index].DiscountAmount;
    }
  
    // Update the state with the new row data
    setRows(updatedRows);
    calculateTotals();
  };


   
  
  

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        BookId: "", // This will be empty for new rows
        SerialNo: "",
        Copies: "",
        Rate: "",
        DiscountPercentage: "",
        DiscountAmount: "",
        Amount: "",
      },
    ]);
    calculateTotals();
  };



  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
    toast.error('Book details Deleted Succefully')
    calculateTotals();
  };





  const fetchBooks = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Bookget.php");
      const bookOptions = response.data.map((book) => ({
        value: book.Id,
        label: book.BookName,
      }));
      setBookOptions(bookOptions);
    } catch (error) {
      toast.error("Error fetching books:", error);
    }
  };

  

  const fetchAccounts = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Accountget.php");
      const accountOptions = response.data.map((acc) => ({
        value: acc.Id,
        label: acc.AccountName,
      }));
      setAccountOptions(accountOptions);
    } catch (error) {
      toast.error("Error fetching Accounts:", error);
    }
  };


  const fetchAssigncanvassors = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/AssignCanvassorget.php");
      const canvassorOptions = response.data.map((can) => ({
        value: can.Id,
        label: can.CanvassorName,
      }));
      setCanvassorOptions(canvassorOptions);
    } catch (error) {
      toast.error("Error fetching Canvassors:", error);
    }
  };

  const fetchDispatchmodes = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Dispatchmodeget.php");
      const dispatchmodeOptions = response.data.map((dis) => ({
        value: dis.Id,
        label: dis.DispatchModeName,
      }));
      setDispatchmodeOptions(dispatchmodeOptions);
    } catch (error) {
      toast.error("Error fetching dispatch modes:", error);
    }
  };


  
 

  const resetForm = () => {
    setInvoiceDate('');
    setInvoiceNo('');
    setBillType('');
    setReceiptDate('');
    setReceiptNo('');
    setWait('');
    setBundles('');
    setFreight('');
    setDispatchModeId('');
    setPaymentMode('');
    setCanvassorId('')
    setOrderDate('');
    setOrderNo('');
    setReceivedOn('');
    setReceivedThrough('');
    setAccountId('');
   setSubTotal('');
    setTotal('');
    setTotalCopies('');
    setRemark('');
    setRows([
      {
        BookId: '',
        Copies: 0,
        Rate: 0,
        DiscountPercentage: 0,
        DiscountAmount: 0,
        Amount: 0,
      },
    ]);
};


useEffect(() => {
  calculateTotals();
}, [rows]);


  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setIsEditing(false);
    setEditingIndex(-1);
  };


  

  
  const handleEdit = (row) => {
    const sellsheader = sellsforcanvassors[row.index];
  
    // Filter purchase details to match the selected PurchaseId
    const sellsdetail = sellsforcanvassordetails.filter(detail => detail.SellsForCanvassorId === sellsheader.Id);

    // Map the details to rows
    const mappedRows = sellsdetail.map(detail => ({

      SellsForCanvassorId:detail.SellsForCanvassorId,
      BookId: detail.BookId,
      Copies: detail.Copies,
      Rate: detail.Rate,
      DiscountPercentage: detail.DiscountPercentage,
      DiscountAmount: detail.DiscountAmount,
      Amount: detail.Amount,
      Id: detail.Id, // Include the detail Id in the mapped row for tracking
    }));

     // Convert date strings to DD-MM-YYYY format
 const convertDateForInput = (dateStr) => {
  if (typeof dateStr === 'string' && dateStr.includes('-')) {
      const [year, month, day] = dateStr.split(' ')[0].split('-');
      return `${year}-${month}-${day}`;
  } else {
      console.error('Invalid date format:', dateStr);
      return ''; // Return an empty string or handle it as needed
  }
};

    const invoicedate = convertDateForInput(sellsheader.InvoiceDate.date);
    const receiptdate = convertDateForInput(sellsheader.ReceiptDate.date);
  const orderdate = convertDateForInput(sellsheader.OrderDate.date)
  const receivedondate = convertDateForInput(sellsheader.ReceivedOn.date)
  
    // Set the form fields
    setInvoiceDate(invoicedate);
    setReceiptDate(receiptdate);
    setOrderDate(orderdate);
    setInvoiceNo(sellsheader.InvoiceNo);
   setBillType(sellsheader.BillType);

    setAccountId(sellsheader.AccountId);
    setReceiptNo(sellsheader.ReceiptNo);
    setWait(sellsheader.Wait);
    setBundles(sellsheader.Bundles);
    setFreight(sellsheader.Freight);
    setDispatchModeId(sellsheader.DispatchModeId);
    setPaymentMode(sellsheader.PaymentMode);
    setOrderNo(sellsheader.OrderNo);
    setReceivedOn(receivedondate);
    setReceivedThrough(sellsheader.ReceivedThrough);
    setCanvassorId(sellsheader.CanvassorId);
    setTotalCopies(sellsheader.TotalCopies);
    setSubTotal(sellsheader.SubTotal);
    setTotal(sellsheader.Total);
    setRemark(sellsheader.Remark)
    console.log(sellsheader, 'sells header');
    console.log(sellsdetail, 'sells detail')
  console.log(mappedRows, 'mapped rows')
    // Set the rows for the table with all the details
    setRows(mappedRows);
  
    // Set editing state
    setEditingIndex(row.index);
    setIsModalOpen(true);
    setIsEditing(true);
    setId(sellsheader.Id);
  
    // Determine which specific detail to edit
    const specificDetail = sellsdetail.find(detail => detail.Id === row.original.Id);
    if (specificDetail) {
      setSellsdetailId(specificDetail.Id); // Set the specific detail Id
    }


    fetchSellsdetailsforcanvassor();
  };
  

  const handleDelete = (index) => {
    setSellsforcanvassors((prevSellsforcanvassors) =>
      prevSellsforcanvassors.filter((_, i) => i !== index)
    );
    toast.error('Sells for Convassor Deleted Successfully!');
  };



  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!InvoiceNo) {
        formErrors.InvoiceNo = "Invoice No is required.";
        isValid = false;
    }
    if (!InvoiceDate) {
        formErrors.InvoiceDate = "Invoice Date is required.";
        isValid = false;
    }

    if (!BillType) {
      formErrors.BillType = "Bill Type is required.";
      isValid = false;
  }
    if (!AccountId) {
        formErrors.AccountId = "Account Id is required.";
        isValid = false;
    }
    if (!ReceiptNo) {
      formErrors.ReceiptNo = "Receipt No is required.";
      isValid = false;
  }


  if (!ReceiptDate) {
    formErrors.ReceiptDate = "Receipt Date is required.";
    isValid = false;
}
if (!Wait) {
    formErrors.Wait = "Wait is required.";
    isValid = false;
}

if (!Bundles) {
  formErrors.Bundles = "Bundles is required.";
  isValid = false;
}
if (!Freight) {
    formErrors.Freight = "Freight is required.";
    isValid = false;
}
if (!DispatchModeId) {
  formErrors.DispatchModeId = "Dispatch Mode Id is required.";
  isValid = false;
}

if (!PaymentMode) {
  formErrors.PaymentMode = "Payment Mode is required.";
  isValid = false;
}
if (!OrderNo) {
    formErrors.OrderNo = "Order No is required.";
    isValid = false;
}
if (!OrderDate) {
  formErrors.OrderDate = "Order Date is required.";
  isValid = false;
}  
if (!ReceivedOn) {
  formErrors.ReceivedOn = "Received On is required.";
  isValid = false;
}  
if (!ReceivedThrough) {
  formErrors.ReceivedThrough = "Received Through is required.";
  isValid = false;
}  
if (!CanvassorId) {
  formErrors.CanvassorId = "Canvassor Id is required.";
  isValid = false;
}  
if (!TotalCopies) {
  formErrors.TotalCopies = "Total Copies is required.";
  isValid = false;
}  
if (!SubTotal) {
  formErrors.SubTotal = "Sub total is required.";
  isValid = false;
}  
if (!Total) {
  formErrors.Total = "Total is required.";
  isValid = false;
}  
if (!Remark) {
  formErrors.Remark = "Remark is required.";
  isValid = false;
}  


    setErrors(formErrors);
    return isValid;
};



const handleSubmit = async (e) => {
  e.preventDefault();
  if (!validateForm()) return;

  const formattedinvoicedate = moment(InvoiceDate).format('YYYY-MM-DD');
  const formattedreceiptdate = moment(ReceiptDate).format('YYYY-MM-DD');

  const formattedorderdate = moment(OrderDate).format('YYYY-MM-DD');
const formattedreceivedon = moment(ReceivedOn).format('YYYY-MM-DD')

  const sellsheaderdata = {
    Id: isEditing ? id : '',  // Include the Id for updating, null for new records
    InvoiceNo: InvoiceNo,
    InvoiceDate: formattedinvoicedate,
    BillType: BillType,
    AccountId: AccountId,
    ReceiptNo: ReceiptNo,
    ReceiptDate: formattedreceiptdate,
    Wait: Wait,
    Bundles: Bundles,
    Freight: Freight,
    DispatchModeId :DispatchModeId,
    PaymentMode: PaymentMode,
    OrderNo: OrderNo,
    OrderDate: formattedorderdate,
    ReceivedOn: formattedreceivedon,
    ReceivedThrough: ReceivedThrough,
    CanvassorId : CanvassorId,
    TotalCopies: TotalCopies, 
    SubTotal:SubTotal,
    Total: Total,
    Remark: Remark, 
  };

  try {
    const sellsheaderurl = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Sellsheaderupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Sellsheaderpost.php";

    // Submit purchase header data
    const response = await axios.post(sellsheaderurl, qs.stringify(sellsheaderdata), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    // const purchasereturnId = isEditing ? id : parseInt(response.data.newId, 10);
    const sellsid = isEditing ? id : parseInt(response.data.Id, 10);

    for (const row of rows) {
      const rowData = {
        SellsForCanvassorId: sellsid,
        SerialNo: rows.indexOf(row) + 1,
        BookId: parseInt(row.BookId, 10),
        Copies: parseInt(row.Copies, 10),
        Rate: parseFloat(row.Rate),
        DiscountPercentage: parseFloat(row.DiscountPercentage),
        DiscountAmount: parseFloat(row.DiscountAmount),
        Amount: parseFloat(row.Amount),
        Id: row.Id,
      };

      
      const sellsdetailurl = isEditing && row.Id
        ? "https://publication.microtechsolutions.co.in/php/Sellsdetailupdate.php"
        : "https://publication.microtechsolutions.co.in/php/Sellsdetailpost.php";

      await axios.post(sellsdetailurl, qs.stringify(rowData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    }

    fetchSellsdetailsforcanvassor(); 
    fetchSellsforcanvassor();
    setIsModalOpen(false);
    toast.success(isEditing ? 'Sells for Convassor & Sells for Convassor updated successfully!' : 'Sells Invoice & Sells Invoice Details added successfully!');
    resetForm(); // Reset the form fields after successful submission
  } catch (error) {
    console.error("Error saving record:", error);
    toast.error('Error saving record!');
  }
};

















 

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
     
      {
        accessorKey: "InvoiceNo",
        header: "Invoice No",
        size: 50,
      },
      {
        accessorKey: "InvoiceDate.date",
        header: "Invoice Date",
        size: 50,
        Cell: ({ cell }) => {
          // Using moment.js to format the date
          const date = moment(cell.getValue()).format('DD-MM-YYYY');
          return <span>{date}</span>;
        },
      },
      // {
      //   accessorKey: "SubTotal",
      //   header: "Sub Total",
      //   size: 50,
      // },

      // {
      //   accessorKey: "Total",
      //   header: "Total",
      //   size: 50,
      // },
      // {
      //   accessorKey: "TotalCopies",
      //   header: "Total Copies",
      //   size: 50,
      // },
      {
        accessorKey: "Remark",
        header: "Remark",
        size: 50,
      },
     
     
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>
                          Edit

            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>


          </div>
        ),
      },
    ],
    [sellsforcanvassors]
  );


  const table = useMaterialReactTable({
    columns,
    data: sellsforcanvassors,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="salestoconvassor-container">
     <h1>
      Sells For Canvassor
     </h1>

     <div className="salestoconvassortable-master">
       <div className="salestoconvassortable1-master">
         <Button
          onClick={handleNewClick}
          style={{ color: "#FFFF",
             fontWeight: "700", background:'#0a60bd', width:'15%' }}
          >
          New
        </Button>
        <div className="salestoconvassortable-container">
          <MaterialReactTable table={table} />
        </div>
      </div>



      {isModalOpen && <div className="salestoconvassor-overlay" onClick={() => setIsModalOpen(false)} />}

      <Modal open={isModalOpen}>
        <div className="salestoconvassor-modal">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "500",
              margin: "2px",
            }}>
            {editingIndex >= 0 ? "Edit Sells For Canvassor"  : "Add Sells For Canvassor"} 
          </h1>
          <form  className="salestoconvassor-form">
            

            
             


              <div>
                <label className="salestoconvassor-label">Invoice No<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="InvoiceNo"
                   name="InvoiceNo" 
                  value={InvoiceNo}
                  onChange={(e)=> setInvoiceNo(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Invoice No"
                  />
                </div>

                
                <div>
                          {errors.InvoiceNo && <b className="error-text">{errors.InvoiceNo}</b>}
                        </div>
              </div>

              <div>
                <label className="salestoconvassor-label">Invoice Date<b className="required">*</b>:</label>
                <div>
                  <input
                  type="date"
                  id="InvoiceDate"
                  name="InvoiceDate" 
                  value={InvoiceDate}
                  onChange={(e)=> setInvoiceDate(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Invoice Date"
                  />
                </div>

                <div>
                          {errors.InvoiceDate && <b className="error-text">{errors.InvoiceDate}</b>}
                        </div>
              </div>



              <div>
                <label className="salestoconvassor-label">Bill Type<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="BillType"
                  name="BillType" 
                  value={BillType}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 1 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
                        setBillType(inputValue); // Only set the value if it's one letter/digit
                    }
                }}
                maxLength={1}     
                 className="salestoconvassor-control"
                  placeholder="Enter BillType"
                  />
                </div>


                <div>
                          {errors.BillType && <b className="error-text">{errors.BillType}</b>}
                        </div>
              </div>
              
              
             
              <div>
                <label className="salestoconvassor-label"> Account Id<b className="required">*</b>:</label>
                <div>
                <Select
                 id="PurchaseAccountId"
                 name="PurchaseAccountId"
                 value={accountOptions.find((option) => option.value === AccountId)}
                 onChange={(option) => setAccountId(option.value)}
                 options={accountOptions} 
                 styles={{
                   control: (base) => ({
                     ...base,
                     width: "170px",
                            marginTop: "10px",
                            borderRadius: "4px",
                            border: "1px solid rgb(223, 222, 222)",
                             marginBottom: '5px'
                   }),
                   menu: (base) => ({
                    ...base,
                    zIndex: 100,
                  }),
                 }}
                  placeholder="Select Acc id"
                />  
                </div>

                <div>
                          {errors.AccountId && <b className="error-text">{errors.AccountId}</b>}
                        </div>
              </div>


              <div>
                <label className="salestoconvassor-label">Receipt No<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="ReceiptNo"
                   name="ReceiptNo" 
                  value={ReceiptNo}
                  onChange={(e)=> setReceiptNo(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Receipt No"
                  />
                </div>


                <div>
                          {errors.ReceiptNo && <b className="error-text">{errors.ReceiptNo}</b>}
                        </div>
              </div>


              <div>
                <label className="salestoconvassor-label">Receipt Date<b className="required">*</b>:</label>
                <div>
                  <input
                  type="date"
                  id="ReceiptDate"
                  name="ReceiptDate" 
                  value={ReceiptDate}
                  onChange={(e)=> setReceiptDate(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Receipt Date"
                  />
                </div>


                <div>
                          {errors.ReceiptDate && <b className="error-text">{errors.ReceiptDate}</b>}
                        </div>
              </div>

              <div>
                <label className="salestoconvassor-label">Wait<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="Wait"
                   name="Wait" 
                  value={Wait}
                  onChange={(e)=> setWait(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Wait"
                  />
                </div>

                <div>
                          {errors.Wait && <b className="error-text">{errors.Wait}</b>}
                        </div>
              </div>

              <div>
                <label className="salestoconvassor-label">Bundles<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="Bundles"
                   name="Bundles" 
                  value={Bundles}
                  onChange={(e)=> setBundles(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Bundles"
                  />
                </div>


                <div>
                          {errors.Bundles && <b className="error-text">{errors.Bundles}</b>}
                        </div>
              </div>


              <div>
                <label className="salestoconvassor-label">Freight<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="Freight"
                   name="Freight" 
                  value={Freight}
                  onChange={(e)=> setFreight(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Freight"
                  />
                </div>

                        <div>
                          {errors.Freight && <b className="error-text">{errors.Freight}</b>}
                        </div>
              </div>


              <div>
                <label className="salestoconvassor-label"> Dispatch Mode Id<b className="required">*</b>:</label>
                <div>
                <Select
                 id="DispatchModeId"
                 name="DispatchModeId"
                 value={dispatchmodeOptions.find((option) => option.value === DispatchModeId)}
                 onChange={(option) => setDispatchModeId(option.value)}
                 options={dispatchmodeOptions} 
                 styles={{
                   control: (base) => ({
                     ...base,
                     width: "170px",
                            marginTop: "10px",
                            borderRadius: "4px",
                            border: "1px solid rgb(223, 222, 222)",
                             marginBottom: '5px'
                   }),
                   menu: (base) => ({
                    ...base,
                    zIndex: 100,
                  }),
                 }}
                  placeholder="Select Dispatch id"
                />  
                </div>

                <div>
                          {errors.DispatchModeId && <b className="error-text">{errors.DispatchModeId}</b>}
                        </div>
              </div>


              <div>
                <label className="salestoconvassor-label">Payment Mode<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="PaymentMode"
                   name="PaymentMode"
                    value={PaymentMode}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue.length <= 1 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
                          setPaymentMode(inputValue); // Only set the value if it's one letter/digit
                      }
                  }}
                  maxLength={1}                    className="salestoconvassor-control"
                  placeholder="Enter Payment Mode"
                  />
                </div>

                <div>
                          {errors.PaymentMode && <b className="error-text">{errors.PaymentMode}</b>}
                        </div>
              </div>



              <div>
                <label className="salestoconvassor-label"> Canvassor Id<b className="required">*</b>:</label>
                <div>
                <Select
                 id="CanvassorId"
                 name="CanvassorId"
                 value={canvassorOptions.find((option) => option.value === CanvassorId)}
                 onChange={(option) => setCanvassorId(option.value)}
                 options={canvassorOptions} 
                 styles={{
                   control: (base) => ({
                     ...base,
                     width: "170px",
                            marginTop: "10px",
                            borderRadius: "4px",
                            border: "1px solid rgb(223, 222, 222)",
                             marginBottom: '5px'
                   }),
                   menu: (base) => ({
                    ...base,
                    zIndex: 100,
                  }),
                 }}
                  placeholder="Select Canvassor id"
                />  
                </div>

                <div>
                          {errors.CanvassorId && <b className="error-text">{errors.CanvassorId}</b>}
                        </div>
              </div>

</form>





  <div className="salestoconvassor-table">
<table>
  <thead>
    <tr>
      <th>Serial No</th>
      <th>Book Name<b className="required">*</b></th>
      <th>Copies<b className="required">*</b></th>
      <th>Rate<b className="required">*</b></th>
      <th>Discount Percentage<b className="required">*</b></th>
      <th>Discount Amount<b className="required">*</b></th>
      <th>Amount<b className="required">*</b></th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
{rows.map((row, index) => (
  <tr key={index}>
    <td>{index + 1}</td>
    <td>
      <Select
        value={bookOptions.find((option) => option.value === row.BookId)}
        onChange={(option) => handleInputChange(index, 'BookId', option.value)}
        options={bookOptions}
        placeholder="Select Book"
        styles={{
          control: (base) => ({
            ...base,
            width: "150px",
          }),

          menu: (base) => ({
            ...base,
            zIndex: 100,
          }),
        }}
      />
    </td>
    <td>
      <input
        type="number"
        value={row.Copies}
        onChange={(e) => handleInputChange(index, 'Copies', e.target.value)}
        placeholder="Copies"
      />
    </td>
    <td>
      <input
        type="number"
        value={row.Rate}
        onChange={(e) => handleInputChange(index, 'Rate', e.target.value)}
        placeholder="Rate"
      />
    </td>
    <td>
      <input
        type="number"
        value={row.DiscountPercentage}
        onChange={(e) => handleInputChange(index, 'DiscountPercentage', e.target.value)}
        placeholder="Discount %"
      />
    </td>
    <td>
      <input
        type="number"
        value={row.DiscountAmount}
        readOnly
        placeholder="Discount Amount"
      />
    </td>
    <td>
      <input
        type="number"
        value={row.Amount}
        readOnly
        placeholder="Amount"
      />
    </td>
    <td>
<div style={{display:'flex', justifyContent:'space-between'}}>

    <Button
  onClick={handleAddRow}
  style={{ background: "#0a60bd", color: "white", marginRight: "5px" }}>
  Add 
</Button>
      <Button
        onClick={() => handleDeleteRow(index)}
        style={{ background: "red", color: "white" }}>
        <RiDeleteBin5Line />
      </Button></div>

    </td>
  </tr>
))}
</tbody>

</table>

</div>
<form className="salestoconvassor-form">
             

             



              <div>
                <label className="salestoconvassor-label">Order No<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="OrderNo"
                   name="OrderNo"
                    value={OrderNo}
                    onChange={(e)=> setOrderNo(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Order No"
                  />
                </div>
                <div>
                          {errors.OrderNo && <b className="error-text">{errors.OrderNo}</b>}
                        </div>
              </div>

              <div>
                <label className="salestoconvassor-label">Order Date<b className="required">*</b>:</label>
                <div>
                  <input
                  type="date"
                  id="OrderDate"
                   name="OrderDate"
                    value={OrderDate}
                    onChange={(e)=> setOrderDate(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Order Date"
                  />
                </div>

                <div>
                          {errors.OrderDate && <b className="error-text">{errors.OrderDate}</b>}
                        </div>
              </div>


              <div>
                <label className="salestoconvassor-label">Received On<b className="required">*</b>:</label>
                <div>
                  <input
                  type="date"
                  id="ReceivedOn"
                   name="ReceivedOn"
                    value={ReceivedOn}
                    onChange={(e)=> setReceivedOn(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Received On"
                  />
                </div>

                <div>
                          {errors.ReceivedOn && <b className="error-text">{errors.ReceivedOn}</b>}
                        </div>
              </div>


              <div>
                <label className="salestoconvassor-label">Received Through<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="ReceivedThrough"
                   name="ReceivedThrough"
                    value={ReceivedThrough}
                    onChange={(e)=> setReceivedThrough(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Received Through"
                  />
                </div>
                <div>
                          {errors.ReceivedThrough && <b className="error-text">{errors.ReceivedThrough}</b>}
                        </div>
              </div>
            
             

           


              <div>
                <label className="salestoconvassor-label">Total Copies<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="TotalCopies"
                   name="TotalCopies"
                    value={TotalCopies}
                    onChange={(e)=> setTotalCopies(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Total Copies"
                  />
                </div>
                <div>
                          {errors.TotalCopies && <b className="error-text">{errors.TotalCopies}</b>}
                        </div>
              </div>


              <div>
                <label className="salestoconvassor-label">Sub Total<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="SubTotal"
                   name="SubTotal"
                    value={SubTotal}
                    onChange={(e)=> setSubTotal(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Sub Total"
                  />
                </div> <div>
                          {errors.SubTotal && <b className="error-text">{errors.SubTotal}</b>}
                        </div>
              </div>

              <div>
                <label className="salestoconvassor-label">Total<b className="required">*</b>:</label>
                <div>
                  <input
                  type="text"
                  id="Total"
                   name="Total"
                    value={Total}                   
                      onChange={(e)=> setTotal(e.target.value)}

                  className="salestoconvassor-control"
                  placeholder="Enter Total "
                  />
                </div> <div>
                          {errors.Total && <b className="error-text">{errors.Total}</b>}
                        </div>
              </div>
             

              <div>
                <label className="salestoconvassor-label">Remarks<b className="required">*</b>:</label>
              <div>
                  <input
                  type="text"
                  id="Remark"
                   name="Remark"
                    value={Remark}
                    onChange={(e)=> setRemark(e.target.value)}
                  className="salestoconvassor-control"
                  placeholder="Enter Remark"
                  />
                </div>
             
               <div>
                          {errors.Remark && <b className="error-text">{errors.Remark}</b>}
                        </div> </div>

              




              </form>
              <div className="salestoconvassor-btn-container">
                <Button
                  type="submit" onClick={handleSubmit}
                  style={{
                    background: "#0a60bd",
                    color: "white",
                  }}>
                   {editingIndex >= 0 ? "Update" : "Save"}
                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}>
                  Cancel
                </Button>
              </div>
           
        </div>
      </Modal>
    </div>
    <ToastContainer/>
  </div>
  );
}

export default Salestoconvassor;
