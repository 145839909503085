import React, { useState, useMemo, useEffect } from "react";
import "./CostCenter.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";


function CostCenter() {
  const [CostCenterName, setCostCenterName] = useState('');
  const [costcenters, setCostcenters] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState('');
  const [errors, setErrors] = useState('');

  const fetchCostcenters = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Costcenterget.php");
      setCostcenters(response.data);
    } catch (error) {
      toast.error("Error fetching Costcenters:", error);
    }
  };

  useEffect(() => {
    fetchCostcenters();
  }, []);

 

  const resetForm = () => {
    setCostCenterName('');
    setIsEditing(false); // Reset isEditing to false
    setId(''); // Clear the id
    setErrors(''); // Clear any previous errors
    setIsModalOpen(false);
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setEditingIndex(-1);
  };

  const handleEdit = (row) => {
    const mode = costcenters[row.index];
    setCostCenterName(mode.CostCenterName); // Ensure this matches your data field
    setEditingIndex(row.index);
    setId(mode.Id);
    setIsEditing(true); // Ensure isEditing is set to true for editing
    setIsModalOpen(true);
  };

  const handleDelete = (index) => {
    setCostcenters((prevCostcenters) => prevCostcenters.filter((_, i) => i !== index));
    toast.error("Costcenter Deleted Successfully!");
  };


  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!CostCenterName) {
      formErrors.CostCenterName = "CostCenter Name is required.";
      isValid = false; 
    }

    setErrors(formErrors);
    return isValid;
  };







const handleSubmit = async (e) => {
  e.preventDefault();

  // Only validate form if it's a POST request (not editing)
  if (!isEditing && !validateForm()) return;

  const data = {
    CostCenterName: CostCenterName,
  };

  const url = isEditing
    ? "https://publication.microtechsolutions.co.in/php/Costcenterupdate.php"
    : "https://publication.microtechsolutions.co.in/php/Costcenterpost.php";

  if (isEditing) {
    data.Id = id;
  }

  try {
    await axios.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    if (isEditing) {
      toast.success('Costcenter  updated successfully!');
    } else {
      toast.success('Costcenter added successfully!');
    }
    setIsModalOpen(false);
    resetForm();
    fetchCostcenters();
  } catch (error) {
    console.error("Error saving record:", error);
    toast.error('Error saving record!');
  }
};
  

  const columns = useMemo(
    () => [
      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
     {
        accessorKey: "CostCenterName",
        header: "Costcenter  Name",
        size: 50,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [costcenters]
  );

  const table = useMaterialReactTable({
    columns,
    data: costcenters, 
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="costcenter-container">
      <h1>Cost Center Master</h1>
      <div className="costcentertable-master">
        <div className="costcentertable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", 
            fontWeight: "700", background:'#0a60bd', width:'15%' }}
>            New
          </Button>
          <div className="costcentertable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>


        {isModalOpen && <div className="costcenter-overlay" onClick={() => setIsModalOpen(false)} />}

        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="costcenter-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}>
              {editingIndex >= 0 ? "Edit Cost Center" : "Add Cost Center"}
            </h1>
            <form onSubmit={handleSubmit} className="costcenter-form">
              <div>
                <label className="costcenter-label">Cost Center Name<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="CostCenterName"
                    name="CostCenterName"
                    value={CostCenterName}
                    onChange={(e) => setCostCenterName(e.target.value)}
                    className="costcenter-control"
                    placeholder="Enter CostCenter Name"
                  />
                  <div>
{errors.CostCenterName && <b className="error-text">{errors.CostCenterName}</b>}
</div>
                </div>
              </div>
            </form>

            <div className="costcenter-btn-container">
              <Button
                type="submit"
                onClick={handleSubmit}
                style={{
                  background: "#0a60bd",
                  alignContent: 'center',
                  color: "white",
                }}>
                                                {editingIndex >= 0 ? "Update" : "Save"}

              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                style={{
                  background: "red",
                  color: "white",
                }}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>

      <ToastContainer/>
    </div>
  );
}

export default CostCenter;
