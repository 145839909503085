import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import "./Godown.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Modal,
  Button,
} from "@mui/material";
import Select from "react-select"; // Import from react-select
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";


function Godown() {
  const [godowns, setGodowns] = useState([]);
  const [GodownName, setGodownName] = useState("");

  const [editingIndex, setEditingIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
const [ isEditing, setIsEditing] = useState(false);
const [ id, setId] = useState('');
const [errors, setErrors] = useState('');




  useEffect(()=>{
    fetchGodowns();
   },[])


  const fetchGodowns = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Godownget.php");
      
      setGodowns(response.data);
    } catch (error) {
      toast.error("Error fetching Godowns:", error);
    }
  };



  const resetForm = () => {
    setGodownName("");
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setEditingIndex(-1);
  };

  const handleEdit = (row) => {
    const godown = godowns[row.index];
    setGodownName(godown.GodownName);
    setEditingIndex(row.index);
    setIsModalOpen(true);
    setIsEditing(true);
    setId(godown.Id);
  };

  const handleDelete = (index) => {
    setGodowns((prevGodowns) => prevGodowns.filter((_, i) => i !== index));
    toast.error("Godown Deleted Successfully!");
  };

  const validateForm = ()=> {
    let formErrors = {};
    let isValid = true;

    
    if (!GodownName) {
        formErrors.GodownName = "Godown Name is required.";
        isValid = false;
    
    }

    
    setErrors(formErrors);
    return isValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
      GodownName : GodownName
    };
  
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Godownupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Godownpost.php";
  
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Godown updated successfully!');
      } else {
        toast.success('Godown added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchGodowns(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
  

 

  
  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "GodownName",
        header: "Godown Name",
        size: 50,
      },
    
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
          <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [godowns]
  );

  const table = useMaterialReactTable({
    columns,
    data: godowns,  muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="godown-container">
      <h1>Godown Master</h1>

      <div className="godowntable-master">
        <div className="godowntable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF",
             fontWeight: "700", background:'#0a60bd', width:'15%' }}
>            New
          </Button>
          <div className="godowntable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>

        {isModalOpen && <div className="godown-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen}>
          <div className="godown-modal">
            <h2
              style={{
                textAlign: "center",
                fontWeight: "600",
                margin: "2px",
                color: "#3c7291",
              }}>
              {editingIndex >= 0 ? "Edit Godown" : "Add Godown"}
            </h2>
            <form  className="godown-form">
              <div>
                <label className="godown-label">Godown Name <b className="required">*</b>:</label>
                <div>
                  <input
                    id="GodownName"
                    name="GodownName"
                    value={GodownName}
                    onChange={(e) => setGodownName(e.target.value)}
                    placeholder="Enter godown name"
                    className="godown-control"
                  />


<div>
                    {errors.GodownName && <b className="error-text">{errors.GodownName}</b>}
                      </div> 
                </div>
              </div>
              
                </form>
                <div className="godown-btn-container">
                  <Button
                    type="submit" onClick={handleSubmit}
                    style={{
                      background: "#0a60bd",
                      color: "white",
                    }}>
                                                      {editingIndex >= 0 ? "Update" : "Save"}

                  </Button>
                  <Button
                    onClick={() => setIsModalOpen(false)}
                    style={{
                      background: "red",
                      color: "white",
                    }}>
                    Cancel
                  </Button>
                </div>
              
          </div>
        </Modal>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default Godown;
