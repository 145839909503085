import React, { useState, useMemo, useEffect } from "react";
import "./University.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios  from "axios";
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
function University() {
  const [UniversityName, setUniversityName] = useState("");
  const [universities, setUniversities] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState('');


  useEffect(()=>{
    fetchUniversities();
   },[])


  const fetchUniversities = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Universityget.php");
      
      setUniversities(response.data);
    } catch (error) {
      toast.error("Error fetching Universities:", error);
    }
  };

  const resetForm = ()=> {
    setUniversityName("");
  }



  const handleNewClick = () => {
    setUniversityName("");
    setEditingIndex(-1);
    setIsModalOpen(true);
  };

 

  const handleEdit = (row) => {
    const university = universities[row.index
    ];
    setUniversityName(university.UniversityName);
    setEditingIndex(row.index);
    setIsModalOpen(true);
    setIsEditing(true);
  setId(university.Id)
  };

  const handleDelete = (index) => {
    setUniversities((prevUniversities) =>
      prevUniversities.filter((_, i) => i !== index)
    );
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    
    if (!UniversityName) {
        formErrors.UniversityName = "University Name is required.";
        isValid = false;
    
    }

    
    setErrors(formErrors);
    return isValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
      UniversityName : UniversityName
    };
  
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Universityupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Universitypost.php";
  
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('University updated successfully!');
      } else {
        toast.success('University added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchUniversities(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "UniversityName",
        header: "University Name",
        size: 50,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px",
              }}>
              Edit
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
                            <RiDeleteBin5Line/>

            </Button>
          </div>
        ),
      },
    ],
    [universities]
  );

  const table = useMaterialReactTable({
    columns,
    data: universities,
    muiTableHeadCellProps: {
      style: {
         backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="university-container">
      <h1>
        University Master
      </h1>
      <div className="universitytable-master">
        <div className="universitytable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", fontWeight: "700", background:'#0a60bd', width:'15%' }}
>            New
          </Button>
          <div className="universitytable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>


        {isModalOpen && <div className="university-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="university-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}>
              {editingIndex >= 0 ? "Edit University" : "Add University"}
            </h1>
            <form onSubmit={handleSubmit} className="university-form">
              <div>
                <label className="university-label">University Name<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="UniversityName" name="UniversityName"
                    value={UniversityName}
                    onChange={(e) => setUniversityName(e.target.value)}
                    placeholder="Enter university Name"
                    className="university-control"
                    
                  />
                    <div>
                    {errors.UniversityName && <b className="error-text">{errors.UniversityName}</b>}
                      </div>   
                </div>
              </div></form>

              <div className="univ-btn-container">
                <Button

                  type="submit" onClick={handleSubmit}
                  style={{
                    background: "#0a60bd",
                    color: "white",
                  }}>
                                                    {editingIndex >= 0 ? "Update" : "Save"}

                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}>
                  Cancel
                </Button>
              </div>
            
          </div>
        </Modal>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default University;
