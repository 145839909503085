import React from 'react'

function FBTstmt() {
  return (
    <div>
      FBTstmt
      FBTstmt
    </div>
  )
}

export default FBTstmt
