import React, { useState, useMemo, useEffect } from "react";
import "./Book.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button } from "@mui/material";
import axios from "axios";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
import moment from 'moment';


function Book() {
  const [BookCode, setBookCode] = useState("");
  const [BookName, setBookName] = useState("");
  const [BookNameMarathi, setBookNameMarathi] = useState('');

  const [BookGroupId, setBookGroupId] = useState("");
  const [bookgroupOptions, setBookgroupOptions] = useState([]);

  const [BookStandardId, setBookStandardId] = useState("");
  const [bookstandardOptions, setBookstandardOptions] = useState([]);

  const [PublicationId, setPublicationId] = useState("");
  const [publicationOptions, setPublicationOptions] = useState([]);

  const [UniversityId, setUniversityId] = useState("");
  const [universityOptions, setUniversityOptions] = useState([]);

  const [BookMediumId, setBookMediumId] = useState("");
  const [bookmediumOptions, setBookmediumOptions] = useState([]);

  const [CurrentEdition, setCurrentEdition] = useState("");
  const [BookRate, setBookRate] = useState("");
  const [BookPages, setBookPages] = useState("");
  const [BookForms, setBookForms] = useState("");
  const [FillingDate, setFilingDate] = useState("");
  const [TitlePages, setTitlePages] = useState('');
  const [TitlePressId, setTitlePressId] = useState("");
  const [titlepressOptions, setTitlepressOptions] = useState([]);

  const [PaperSizeId, setPaperSizeId] = useState("");
  const [papersizeOptions, setPapersizeOptions] = useState([]);

  const [PressId, setPressId] = useState("");
  const [pressOptions, setPressOptions] = useState([]);

  const [Status, setStatus] = useState("");
  const [OpeningStock, setOpeningStock] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [books, setBooks] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [id, setId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
const [errors, setErrors] = useState('')


  useEffect(() => {
    fetchAllBooks();
    fetchBookgroups();fetchStandards();fetchPublications();
    fetchUniversities();
    fetchmediums();
    fetchTitlepresses(); fetchPapersize(); fetchPresses();

  }, []);

  const fetchAllBooks = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Bookget.php");
      setBooks(response.data);
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };


  const fetchBookgroups = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/BookGroupget.php");

      const bookgroupOptions = response.data.map((group) => ({
        value: group.Id,
        label: group.BookGroupName,
      }));
      setBookgroupOptions(bookgroupOptions);
    } catch (error) {
      console.error("Error fetching book groups:", error);
    }
  };

  const fetchStandards = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Standardget.php");
      const bookstandardOptions = response.data.map((std) => ({
        value: std.Id,
        label: std.StandardName,
      }));
      setBookstandardOptions(bookstandardOptions);
    } catch (error) {
      console.error("Error fetching  standards:", error);
    }
  };

  const fetchPublications = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Publicationget.php");
      const publicationOptions = response.data.map((pub) => ({
        value: pub.Id,
        label: pub.PublicationName,
      }));
      setPublicationOptions(publicationOptions);
    } catch (error) {
      console.error("Error fetching  publications:", error);
    }
  };
  const fetchUniversities = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Universityget.php");
      const universityOptions = response.data.map((uni) => ({
        value: uni.Id,
        label: uni.UniversityName,
      }));
      setUniversityOptions(universityOptions);
    } catch (error) {
      console.error("Error fetching  universities:", error);
    }
  };
  const fetchmediums = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/BookMediumget.php");
      const bookmediumOptions = response.data.map((med) => ({
        value: med.Id,
        label: med.BookMediumName,
      }));
      setBookmediumOptions(bookmediumOptions);
    } catch (error) {
      console.error("Error fetching  mediums:", error);
    }
  };
  const fetchTitlepresses = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/TitlePressget.php");
      const titlepressOptions = response.data.map((title) => ({
        value: title.Id,
        label: title.TitlePressName,
      }));
      setTitlepressOptions(titlepressOptions);
    } catch (error) {
      console.error("Error fetching  title press:", error);
    }
  };
  const fetchPapersize = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/PaperSizeget.php");
      const papersizeOptions = response.data.map((paper) => ({
        value: paper.Id,
        label: paper.PaperSizeName,
      }));
      setPapersizeOptions(papersizeOptions);
    } catch (error) {
      console.error("Error fetching  papersize:", error);
    }
  };
  const fetchPresses = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/PressMasterget.php");
      const pressOptions = response.data.map((press) => ({
        value: press.Id,
        label: press.PressName,
      }));
      setPressOptions(pressOptions);
    } catch (error) {
      console.error("Error fetching  presses:", error);
    }
  };

  


 

  const resetForm = () => {
    setBookCode("");
    setBookName("");
    setBookGroupId("");
    setBookStandardId("");
    setPublicationId("");
    setUniversityId("");
    setBookMediumId("");
    setCurrentEdition("");
    setBookRate("");
    setBookPages("");
    setBookForms("");
    setFilingDate("");
    setTitlePages('');
    setTitlePressId("");
    setPaperSizeId("");
    setPressId("");
    setStatus("");
    setOpeningStock("");
    setIsModalOpen(false);
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);

    setEditingIndex(-1);
  };

  const handleEdit = (row) => {



      // Convert date strings to YYYY-MM-DD format for the input fields
      const convertDateForInput = (dateStr) => {
        if (typeof dateStr === 'string' && dateStr.includes('-')) {
            const [year, month, day] = dateStr.split(' ')[0].split('-');
            return `${year}-${month}-${day}`;
        } else {
            console.error('Invalid date format:', dateStr);
            return ''; // Return an empty string or handle it as needed
        }
    };


    const book = books[row.index];
    setBookCode(book.BookCode);
    setBookName(book.BookName);
    setBookNameMarathi(book.BookNameMarathi)
    setBookGroupId(book.BookGroupId);
    setBookStandardId(book.BookStandardId);
    setPublicationId(book.PublicationId);
    setUniversityId(book.UniversityId);
    setBookMediumId(book.BookMediumId);
    setCurrentEdition(book.CurrentEdition);
    setBookRate(book.BookRate);
    setBookPages(book.BookPages);
    setBookForms(book.BookForms);
    setFilingDate(convertDateForInput(book.FillingDate.date));
    setTitlePages(book.TitlePages);
    setTitlePressId(book.TitlePressId);
    setPaperSizeId(book.PaperSizeId);
    setPressId(book.PressId);
    setStatus(book.Status);
    setOpeningStock(book.OpeningStock);
    setEditingIndex(row.index);
    setIsModalOpen(true);
    setIsEditing(true);
    setId(book.Id);
  };

  const handleDelete = (index) => {
    setBooks((prevBooks) => prevBooks.filter((_, i) => i !== index));
    toast.error("Book Deleted Successfully!");
  };


  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!BookCode) {
        formErrors.BookCode = "Book Code is required.";
        isValid = false;
    }

    if (!BookName) {
        formErrors.BookName = "Book Name is required.";
        isValid = false;
    }

    

    if (!BookNameMarathi) {
        formErrors.BookNameMarathi = "Book Name Marathi is required.";
        isValid = false;
    }

    if (!BookGroupId) {
        formErrors.BookGroupId = "Book group id is required.";
        isValid = false;
    }

    if (!BookStandardId) {
        formErrors.BookStandardId = "Book standard id is required.";
        isValid = false;
    }

    if (!PublicationId) {
      formErrors.PublicationId = "Publication id is required.";
      isValid = false;
  }

  if (!UniversityId) {
    formErrors.UniversityId = "University id is required.";
    isValid = false;
}

    if (!BookMediumId) {
        formErrors.BookMediumId = "Book Medium Id is required.";
        isValid = false;
    }

    if (!CurrentEdition) {
        formErrors.CurrentEdition = "Current Edition is required.";
        isValid = false;
    } 

    if (!BookRate) {
      formErrors.BookRate = "Book rate is required.";
      isValid = false;
  } 
  if (!BookPages) {
formErrors.BookPages = "Book pages are  required.";
    isValid = false;
} 
if (!BookForms) {
  formErrors.BookForms = "Book forms are required.";
  isValid = false;
}

if (!TitlePages) {
  formErrors.TitlePages = "Title pages are required.";
  isValid = false;
}

if (!FillingDate) {
  formErrors.FillingDate = "Filing date is required.";
  isValid = false;
}

if (!TitlePressId) {
  formErrors.TitlePressId = "Title press id is required.";
  isValid = false;
}

if (!PaperSizeId) {
  formErrors.PaperSizeId = "Paper size id is required.";
  isValid = false;
}

if (!PressId) {
  formErrors.PressId = "Press id is required.";
  isValid = false;
}

    

    if (!Status) {
        formErrors.Status = "Status is required.";
        isValid = false;
    }

    if (!OpeningStock) {
      formErrors.OpeningStock = "Opening stock is required.";
      isValid = false;
  }

    setErrors(formErrors);
    return isValid;
};

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;



   const formattedFilingDate = moment(FillingDate).format('YYYY-MM-DD');

    const data = {
      BookCode: BookCode,
      BookName : BookName,
      BookNameMarathi : BookNameMarathi,
      BookGroupId : BookGroupId,
      BookStandardId: BookStandardId,
      PublicationId: BookStandardId,
      UniversityId: UniversityId,
      BookMediumId: BookMediumId,
      CurrentEdition : CurrentEdition,
      BookRate : BookRate,
      BookPages : BookPages, 
      BookForms : BookForms,
       FillingDate : formattedFilingDate,
      TitlePages : TitlePages, 
      TitlePressId : TitlePressId,
      PaperSizeId : PaperSizeId,
      PressId: PressId,
      Status: Status,
      OpeningStock: OpeningStock
      

    };
  
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Bookupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Bookpost.php";
  
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Book updated successfully!');
      } else {
        toast.success('Book added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchAllBooks(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "BookName",
        header: "Book Name",
        size: 50,
      },
      {
        accessorKey: "BookRate",
        header: "Book Rate",
        size: 50,
      }, {
        accessorKey: "BookPages",
        header: "Book Pages",
        size: 50,
      }, 

      {
        accessorKey: "PublicationId",
        header: "Publication Id",
        size: 50,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [books]
  );

  const table = useMaterialReactTable({
    columns,
    data: books,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black", 
        fontSize: '16px'
      },
    },
  });

  return (
    <div className="book-container">
      <h1 >Book Master</h1>

      <div className="booktable-master">
        <div className="booktable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", fontWeight: "700", background:'#0a60bd', width:'15%'


            }}>
            New
          </Button>
          <div className="booktable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>

        {isModalOpen && <div className="book-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen} >
          <div className="book-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}>
              {editingIndex >= 0 ? "Edit Book" : "Add New Book"}
            </h1>
            <form onSubmit={handleSubmit} className="masterbook-form">
              <div>
                <label className="book-label">Book Code<b className="required">*</b></label>{" "}
                <div>
                  <input
                    type="text"
                    id="BookCode"
                    name="BookCode"
                    value={BookCode}
                    onChange={(e) => setBookCode(e.target.value)}
                    className="masterbook-control"
                    placeholder="Enter Book Code"
                  />

            <div>
                    {errors.BookCode && <b className="error-text">{errors.BookCode}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Book Name<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="BookName"
                    name="BookName"
                    value={BookName}
                    onChange={(e) => setBookName(e.target.value)}
                    className="masterbook-control"
                    placeholder="Enter Book Name"
                  />

<div>
                    {errors.BookName && <b className="error-text">{errors.BookName}</b>}
                  </div>
                </div>
              </div>


              <div>
  <label className="book-label">Book Name Marathi<b className="required">*</b></label>
  <div> 
        <input

          id="BookNameMarathi"
          name="BookNameMarathi"
          value={BookNameMarathi}
          onChange={(e) => setBookNameMarathi(e.target.value)}
          lang="marathi" className="masterbook-control"
          placeholder="Enter Book Name Marathi"
        />


    <div>
      {errors.BookNameMarathi && <b className="error-text">{errors.BookNameMarathi}</b>}
    </div>
  </div>
</div>

              <div>
                <label className="book-label">Book Group<b className="required">*</b></label>
                <div>
                  <Select
                    id="BookGroupId"
                    name="BookGroupId"
                    value={bookgroupOptions.find((option) => option.value === BookGroupId)}
                    onChange={(option) => setBookGroupId(option.value)}
                    options={bookgroupOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}


                    


                    placeholder="Select  Group"
                  />

<div>
                    {errors.BookGroupId && <b className="error-text">{errors.BookGroupId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Book Standard<b className="required">*</b></label>
                <div>
                <Select
                    id="BookStandardId"
                    name="BookStandardId"
                    value={bookstandardOptions.find((option) => option.value === BookStandardId)}
                    onChange={(option) => setBookStandardId(option.value)}
                    options={bookstandardOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                        marginTop: "10px",marginBottom: '5px',
                        border: '1px solid rgb(223, 222, 222)',
                        borderRadius:'4px',
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 100,
                      }),
                    }}
                    placeholder="Select Standard "
                  />

<div>
                    {errors.BookStandardId && <b className="error-text">{errors.BookStandardId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Publication<b className="required">*</b></label>{" "}
                <div>
                <Select
                    id="PublicationId"
                    name="PublicationId"
                    value={publicationOptions.find((option) => option.value === PublicationId)}
                    onChange={(option) => setPublicationId(option.value)}
                    options={publicationOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Publication "
                  />

<div>
                    {errors.PublicationId && <b className="error-text">{errors.PublicationId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">University<b className="required">*</b></label>
                <div>
                <Select
                    id="UniversityId"
                    name="UniversityId"
                    value={universityOptions.find((option) => option.value === UniversityId)}
                    onChange={(option) => setUniversityId(option.value)}
                    options={universityOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Select University "
                  />

<div>
                    {errors.UniversityId && <b className="error-text">{errors.UniversityId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Book Medium<b className="required">*</b></label>
                <div>
                <Select
                    id="BookMediumId"
                    name="BookMediumId"
                    value={bookmediumOptions.find((option) => option.value === BookMediumId)}
                    onChange={(option) => setBookMediumId(option.value)}
                    options={bookmediumOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Select Medium "
                  />

<div>
                    {errors.BookMediumId && <b className="error-text">{errors.BookMediumId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Current Edition<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="CurrentEdition"
                    name="CurrentEdition"
                    value={CurrentEdition}
                    onChange={(e) => setCurrentEdition(e.target.value)}
                    className="masterbook-control"
                    placeholder="Enter Current Edition"
                  />

<div>
                    {errors.CurrentEdition && <b className="error-text">{errors.CurrentEdition}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Book Rate<b className="required">*</b></label>{" "}
                <div>
                  <input
                    type="text"
                    id="BookRate"
                    name="BookRate"
                    value={BookRate}
                    onChange={(e) => setBookRate(e.target.value)}
                    className="masterbook-control"
                    placeholder="Enter Book Rate"
                  />
                  <div>
                    {errors.BookRate && <b className="error-text">{errors.BookRate}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Book Pages<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="BookPages"
                    name="BookPages"
                    value={BookPages}
                    onChange={(e) => setBookPages(e.target.value)}
                    className="masterbook-control"
                    placeholder="Enter Book Pages"
                  />

<div>
                    {errors.BookPages && <b className="error-text">{errors.BookPages}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Book Forms<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="BookForms"
                    name="BookForms"
                    value={BookForms}
                    onChange={(e) => setBookForms(e.target.value)}
                    className="masterbook-control"
                    placeholder="Enter Book Forms"
                  />
                  <div>
                    {errors.BookForms && <b className="error-text">{errors.BookForms}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Filing Date<b className="required">*</b></label>
                <div>
                  <input
                    type="date"
                    id="FillingDate"
                    name="FillingDate"
                    value={FillingDate}
                    onChange={(e) => setFilingDate(e.target.value)}
                     className="masterbook-control"

                    placeholder="Enter Filing Date"
                  />

<div>
                    {errors.FillingDate && <b className="error-text">{errors.FillingDate}</b>}
                  </div>
                </div>
              </div>

              <div>
                <label className="book-label">Title Pages<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="TitlePages"
                    name="TitlePages"
                    value={TitlePages}
                    onChange={(e) => setTitlePages(e.target.value)}
                     className="masterbook-control"

                    placeholder="Enter Title pages"
                  />

<div>
                    {errors.TitlePages && <b className="error-text">{errors.TitlePages}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Title Press<b className="required">*</b></label>{" "}
                <div>
                <Select
                    id="TitlePressId"
                    name="TitlePressId"
                    value={titlepressOptions.find((option) => option.value === TitlePressId)}
                    onChange={(option) => setTitlePressId(option.value)}
                    options={titlepressOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Select title press "
                  />

<div>
                    {errors.TitlePressId && <b className="error-text">{errors.TitlePressId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Paper Size<b className="required">*</b></label>
                <div>
                <Select
                    id="PaperSizeId"
                    name="PaperSizeId"
                    value={papersizeOptions.find((option) => option.value === PaperSizeId)}
                    onChange={(option) => setPaperSizeId(option.value)}
                    options={papersizeOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Select papersize "
                  />

<div>
                    {errors.PaperSizeId && <b className="error-text">{errors.PaperSizeId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Press<b className="required">*</b></label>
                <div>
                <Select
                    id="PressId"
                    name="PressId"
                    value={pressOptions.find((option) => option.value === PressId)}
                    onChange={(option) => setPressId(option.value)}
                    options={pressOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Select Press "
                  />
                  <div>
                    {errors.PressId && <b className="error-text">{errors.PressId}</b>}
                  </div>
                </div>
              </div>{" "}
              <div>
                <label className="book-label">Status<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="Status"
                    name="Status"
                    value={Status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="masterbook-control"
                    placeholder="Enter Status"
                  />

<div>
                    {errors.Status && <b className="error-text">{errors.Status}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Opening stock<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="OpeningStock"
                    name="OpeningStock"
                    value={OpeningStock}
                    onChange={(e) => setOpeningStock(e.target.value)}
                    className="masterbook-control"
                    placeholder="Enter Opening Stock"
                  />

<div>
                    {errors.OpeningStock && <b className="error-text">{errors.OpeningStock}</b>}
                  </div>
                </div></div>
                </form>
              
              <div className="book-btn-container">
                <Button
                onClick={handleSubmit}
                  type="submit"
                  style={{
                    background: "#0a60bd",
                   alignContent:'center',
                    color: "white",
                  }}>
                  {editingIndex >= 0 ? "Update" : "Save"}
                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}>
                  Cancel
                </Button>
              </div>
          </div>
        </Modal>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default Book;
