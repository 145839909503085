import React from 'react'

function Alldocuments() {
  return (
    <div>
      Alldocuments
    </div>
  )
}

export default Alldocuments
