import React from 'react'

function Partylisting() {
  return (
    <div>
      Partylisting
      Partylisting
    </div>
  )
}

export default Partylisting
