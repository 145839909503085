import React, { useState, useMemo, useEffect } from "react";
import "./Commission.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
function Commission() {
  const [CommissionType, setCommissionType] = useState('');
  const [Percentage, setPercentage] = useState('');
  const [commissions, setCommissions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState('');
  const [errors, setErrors] = useState('');

  const fetchCommssions = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Commissionget.php");
      setCommissions(response.data);
    } catch (error) {
      toast.error("Error fetching commissions:", error);
    }
  };

  useEffect(() => {
    fetchCommssions();
  }, []);

 

  const resetForm = () => {
    setCommissionType('');
    setPercentage('');
    setIsEditing(false); // Reset isEditing to false
    setId(''); // Clear the id
    setErrors(''); // Clear any previous errors
    setIsModalOpen(false);
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setEditingIndex(-1);
  };

  const handleEdit = (row) => {
    const comm = commissions[row.index];
    setCommissionType(comm.CommissionType); // Ensure this matches your data field
    setPercentage(comm.Percentage);
    setEditingIndex(row.index);
    setIsEditing(true); // Ensure isEditing is set to true for editing
    setIsModalOpen(true);    setId(comm.Id);

  };

  const handleDelete = (index) => {
    setCommissions((prevPresses) => prevPresses.filter((_, i) => i !== index));
    toast.success("Commission Deleted Successfully!");
  };


  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!CommissionType) {
      formErrors.CommissionType = "Commission type is required.";
      isValid = false; 
    }
    
    if (!Percentage) {
        formErrors.Percentage = "Percentage is required.";
        isValid = false; 
      }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Only validate form if it's a POST request (not editing)
    if (!isEditing && !validateForm()) return;
  
    const data = {
      CommissionType: CommissionType,
      Percentage: Percentage
    };
  
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Commissionupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Commissionpost.php";
  
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Commission updated successfully!');
      } else {
        toast.success('Commission added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchCommssions();
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
  

  const columns = useMemo(
    () => [
      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "CommissionType",
        header: "Commission Type",
        size: 50,
      },
      {
        accessorKey: "Percentage",
        header: "Percentage",
        size: 50,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px",
              }}>
              Edit
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
                                          <RiDeleteBin5Line/>

            </Button>
          </div>
        ),
      },
    ],
    [commissions]
  );

  const table = useMaterialReactTable({
    columns,
    data: commissions,
    muiTableHeadCellProps: {
      style: {
         backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="commission-container">
      <h1>Commission Master</h1>
      <div className="commissiontable-master">
        <div className="commissiontable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", fontWeight: "700", background:'#0a60bd', width:'15%' }}
            >
            New
          </Button>
          <div className="commissiontable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>

        {isModalOpen && <div className="commission-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="commission-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}>
              {editingIndex >= 0 ? "Edit Commission" : "Add Commission"}
            </h1>
            <form  className="commission-form">
              <div>
                <label className="commission-label">Commission Type<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="CommissionType"
                    name="CommissionType"
                    value={CommissionType}
                    onChange={(e) => setCommissionType(e.target.value)}
                    className="commission-control"
                    placeholder="Enter Commission type"
                  />
                  <div>
{errors.CommissionType && <b className="error-text">{errors.CommissionType}</b>}
</div>
                </div>
              </div>


              <div>
                <label className="commission-label">Percentage<b className="required">*</b> </label>
                <div>
                  <input
                    type="text"
                    id="Percentage"
                    name="Percentage"
                    value={Percentage}
                    onChange={(e) => setPercentage(e.target.value)}
                    className="commission-control"
                    placeholder="Enter Percentage"
                  />
                  <div>
{errors.Percentage && <b className="error-text">{errors.Percentage}</b>}
</div>
                </div>
              </div>
            </form>

            <div className="commission-btn-container">
              <Button
                type="submit"
                onClick={handleSubmit}
                style={{
                  background: "#0a60bd",
                  alignContent: 'center',
                  color: "white",
                }}>
                                                {editingIndex >= 0 ? "Update" : "Save"}

              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                style={{
                  background: "red",
                  color: "white",
                }}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>

      <ToastContainer/>
    </div>
  );
}

export default Commission;
