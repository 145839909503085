import React from 'react'

function Splitdata() {
  return (
    <div>
      Splitdata
      Splitdata
    </div>
  )
}

export default Splitdata
