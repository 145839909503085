import React from 'react'

function Interestcal() {
  return (
    <div>
      Interestcal
      Interestcal
    </div>
  )
}

export default Interestcal
