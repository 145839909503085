import React from 'react'

function Restoredata() {
  return (
    <div>
      Restoredata
      Restoredata
    </div>
  )
}

export default Restoredata
