import React from 'react'

function Netsale() {
  return (
    <div>
      Netsale
      Netsale
    </div>
  )
}

export default Netsale
