import React from 'react'

function Userrights() {
  return (
    <div>
      Userrights
      Userrights
    </div>
  )
}

export default Userrights
