import React from 'react'

function Netsalesummary() {
  return (
    <div>
      Netsalesummary
      Netsalesummary
    </div>
  )
}

export default Netsalesummary
