import React from 'react'

function Lockdata() {
  return (
    <div>
      Lockdata
      Lockdata
    </div>
  )
}

export default Lockdata
