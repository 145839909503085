import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import "./Standard.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";



function Standard() {
  const [StandardName, setStandardName] = useState("");
  const [standards, setStandards] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
const [id, setId ] = useState('');
const [isEditing, setIsEditing] = useState(false);
const [errors, setErrors] = useState('');


  const fetchStandards = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Standardget.php");
      setStandards(response.data);
    } catch (error) {
      toast.error("Error fetching standards:", error);
    }
  };

  useEffect(() => {
    fetchStandards();
  }, []);

  const handleNewClick = () => {
    resetForm();
    setEditingIndex(-1);
    setIsModalOpen(true);
    
  };

 

  


  const handleEdit = (row) => {
    const standard = standards[row.index];
    setStandardName(standard.StandardName);
    setEditingIndex(row.index);
    setId(standard.Id);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const resetForm = () => {
    setStandardName('');
    
  }


  const handleDelete = (index) => {
    setStandards((prevStandards) =>
      prevStandards.filter((_, i) => i !== index)
    );
    toast.error('Standard Deleted Successfully!')
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;
  
    if (!StandardName) {
      formErrors.StandardName = "Standard Name is required.";
      isValid = false;
    }
  
    setErrors(formErrors);
    return isValid;
  };
  

 


  


  const handleSubmit = async (e) => {
     e.preventDefault(); 
   if (!validateForm()) return;

    const data = {
     StandardName :  StandardName
    };
  
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Standardupdate.php"
      :"https://publication.microtechsolutions.co.in/php/Standardpost.php";
  
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Standard updated successfully!');
      } else {
        toast.success('Standard added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchStandards(); 
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
  

  

  

  

  const columns = useMemo(
    () => [
      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "StandardName",
        header: "Standard Name",
        size: 50,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [standards]
  );

  const table = useMaterialReactTable({
    columns,
    data: standards,
    muiTableHeadCellProps: {
      style: {
       backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });



  return (
    <div className="standard-container">
      <h1>
        Standard Master
      </h1>
      <div className="standardtable-master">
        <div className="standardtable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", fontWeight: "700", 
              background:'#0a60bd', width:'15%'
              }}  >
            New
          </Button>
          <div className="standardtable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>


        {isModalOpen && <div className="standard-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen}>
          <div className="standard-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}>
              {editingIndex >= 0 ? "Edit Standard" : "Add Standard"}
            </h1>

            <form  className="standard-form">
              <div>
                <label className="standard-label">Standard Name<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="StandardName" name="StandardName"
                    value={StandardName}
                    onChange={(e) => setStandardName(e.target.value)}
                    placeholder="Enter standard Name"
                    className="standard-control"
                    
                  />
                    <div>
                    {errors.StandardName && <b className="error-text">{errors.StandardName}</b>}
                      </div>   
                </div>
              </div></form>

              <div className="std-btn-container">
                <Button

                  type="submit" 
                  onClick={handleSubmit}
                  style={{
                    background: "#0a60bd",
                    color: "white",
                  }}>
            {editingIndex >= 0 ? "Update" : "Save"}

                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}>
                  Cancel
                </Button>
              </div>
          </div>
        </Modal>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default Standard;


