
import React, { useState, useMemo ,useEffect } from "react";
import "./Accounts.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import axios from "axios";
import { Modal, Button, TextField } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Box, Grid, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";


function Accounts(){
 
 //All Checkboxes
  const [IsSubsidiary, setIsSubsidiary] = useState(false);
  const [IsTDSApplicable, setIsTDSApplicable] = useState(false);
  const [IsFBT, setIsFBT] = useState(false);
  const [IsFreeze, setIsFreeze] = useState(false);
  

 

  const [editingIndex, setEditingIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
//All the Post parameters for the Account Post 
  const [AccountName, setAccountName] = useState("");
  const [GroupId, setGroupId] = useState("");
  const [SubGroupId, setSubGroupId] = useState("");
  const [OpeningBalance, setOpeningBalance] = useState("");
  const [DrORCr, setDrOrCr] = useState("");
  const [TypeCode, setTypeCode] = useState("");
  const [IsSystem, setIsSystem] = useState(false);
  const [Depriciation, setDepriciation] = useState("");

  //All the Post parameters for the Address Post
  const [MobileNo, setMobileNo] = useState("");
  const [TelephoneNo, setTelephoneNo] = useState("");
  const [Pincode, setPincode] = useState("");
  const [FaxNo, setFaxNo] = useState("");
  const[PANNo, setPANNo] = useState('');
  const [GSTNo, setGSTNo] = useState('');
  const [EmailId, setEmailId] = useState('');
  const [Address1, setAddress1] = useState('');
  const [Address2, setAddress2] = useState('');
  const [Address3, setAddress3] = useState('');

  const [CityId, setCityId] = useState("");
  const [StateId, setStateId] = useState("");
  const [AreaId, setAreaId] = useState("");
  const [CountryId, setCountryId] = useState('');
  const [TDSId, setTDSId] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [areaOptions, setareaOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [tdsOptions, setTdsOptions] = useState([]);
  const [accountgroupOptions, setAccountgroupOptions] = useState([]);
  const [subgroupOptions, setSubgroupOptions] = useState([]);
  const [isAddressVisible, setIsAddressVisible] = useState(false); // State for address visibility

  const [errors, setErrors] = useState('');
  const [accountid, setAccountid] = useState('');
  const [addressid, setAddressid] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [isEditing, setIsEditing] = useState(false);






useEffect(()=>{
  fetchAccounts();
  fetchGroupIds();
  fetchSubGroupIds();
  fetchCountries();
  fetchAllCities();
fetchStates();
fetchAreas();
fetchTDS();
fetchAddresses();
}, [])


  const fetchAccounts = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Accountget.php");
      setAccounts(response.data);
    } catch (error) {
      toast.error("Error fetching accounts:", error);
    }
  };


  const fetchAddresses = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Addressget.php");
      setAddresses(response.data);
    } catch (error) {
      toast.error("Error fetching addresses:", error);
    }
  };


  const fetchGroupIds = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/AccountGroupget.php");
      const accountgroupOptions = response.data.map((acc) => ({
        value: acc.Id,
        label: acc.GroupName,
      }));
      setAccountgroupOptions(accountgroupOptions);
    } catch (error) {
      toast.error("Error fetching group ids:", error);
    }
  };


  const fetchSubGroupIds = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/SubAccountGroupget.php");
      const subgroupOptions = response.data.map((sub) => ({
        value: sub.Id,
        label: sub.SubGroupName,
      }));
      setSubgroupOptions(subgroupOptions);
    } catch (error) {
      toast.error("Error fetching subgroup ids:", error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Countryget.php");
      const countryOptions = response.data.map((con) => ({
        value: con.Id,
        label: con.CountryName,
      }));
      setCountryOptions(countryOptions);
    } catch (error) {
      toast.error("Error fetching countries:", error);
    }
  };


  const fetchStates = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/State.php");
      const stateOptions = response.data.map((state) => ({
        value: state.Id,
        label: state.StateName,
      }));
      setStateOptions(stateOptions);
    } catch (error) {
      toast.error("Error fetching states:", error);
    }
  };




  const fetchAllCities = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Cityget.php");
      const cityOptions = response.data.map((city) => ({
        value: city.Id,
        label: city.CityName,
      }));
      setCityOptions(cityOptions);
    } catch (error) {
      toast.error("Error fetching cities:", error);
    }
  };


  const fetchAreas = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Areaget.php");
      const areaOptions = response.data.map((area) => ({
        value: area.Id,
        label: area.AreaName,
      }));
      setareaOptions(areaOptions);
    } catch (error) {
      toast.error("Error fetching areas:", error);
    }
  };


  const fetchTDS = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/TDSMasterget.php");
      const tdsOptions = response.data.map((tds) => ({
        value: tds.Id,
        label: tds.TDSHead,
      }));
      setTdsOptions(tdsOptions);
    } catch (error) {
      toast.error("Error fetching tds ids:", error);
    }
  };


  const handleLabelClick = () => {
    setIsAddressVisible(!isAddressVisible); // Toggle visibility
  };


  const handleNewClick = () => {
    resetForm();
    setIsEditing(false);
    setIsModalOpen(true);
    setEditingIndex(-1);
  };





  const handleEdit = (row) => {
    const account = accounts[row.index]
    const address = addresses[row.index];

    console.log(account, 'account')
    console.log(address, 'address');
    if (!account) {
      toast.error("Invalid account data.");
      return;
    }

    // Set account fields
    setAccountName(account.AccountName);
    setGroupId(account.GroupId);
    setSubGroupId(account.SubGroupId);
    setOpeningBalance(account.OpeningBalance);
    setDrOrCr(account.DrORCr);
    setTypeCode(account.TypeCode);
    setIsSystem(account.IsSystem);
    setDepriciation(account.Depriciation);

    // Check if address is available
    if (address) {
      // Set address fields
      setAddress1(address.Address1);
      setAddress2(address.Address2);
      setAddress3(address.Address3);
      setAreaId(address.AreaId);
      setCityId(address.CityId);
      setStateId(address.StateId);
      setPincode(address.Pincode);
      setCountryId(address.CountryId);
      setTelephoneNo(address.TelephoneNo);
      setFaxNo(address.FaxNo);
      setMobileNo(address.MobileNo);
      setEmailId(address.EmailId);
      setPANNo(address.PANNo);
      setGSTNo(address.GSTNo);
      setIsSubsidiary(address.IsSubsidiary);
      setIsTDSApplicable(address.IsTDSApplicable);
      setIsFBT(address.IsFBT);
      setIsFreeze(address.ISFreeze);
      setTDSId(address.TDSId);
      setAddressid(address.Id);
    } else {
      toast.error("Address data is missing.");
    }

    setEditingIndex(row.index);
    setIsEditing(true);
    setIsModalOpen(true);
    setAccountid(account.Id);
  };








  const handleDelete = (index) => {
    setAccounts((prevAccounts) => prevAccounts.filter((_, i) => i !== index));
    toast.error('Account Deleted Successfully!');

  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!AccountName) {
      formErrors.AccountName = "Account Name is required.";
      isValid = false;
    }

    if (!GroupId) {
      formErrors.GroupId = "Group id is required.";
      isValid = false;
    }

    if (!SubGroupId) {
      formErrors.SubGroupId = "Sub Group id is required.";
      isValid = false;
    }
    if (!OpeningBalance) {
      formErrors.OpeningBalance = "Opening Balance is required.";
      isValid = false;
    }


    if (!DrORCr) {
      formErrors.DrORCr = "Debit or credit is required.";
      isValid = false;
    }

    if (!TypeCode) {
      formErrors.TypeCode = "Type code is required.";
      isValid = false;
    }

    if (!Depriciation) {
      formErrors.Depriciation = "Depriciation is required.";
      isValid = false;
    }




    // Email ID
    if (!EmailId) {
      formErrors.EmailId = "Email Id is required.";
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(EmailId)) {
      formErrors.EmailId = "Email Id is invalid.";
      isValid = false;
    }

    // Address
    if (!Address1) {
      formErrors.Address1 = "Address is required.";
      isValid = false;
    }

    // Area
    if (!AreaId) {
      formErrors.AreaId = "Area is required.";
      isValid = false;
    }

    // State
    if (!StateId) {
      formErrors.StateId = "State is required.";
      isValid = false;
    }

    // City
    if (!CityId) {
      formErrors.CityId = "City is required.";
      isValid = false;
    }
    if (!CountryId) {
      formErrors.CountryId = "Country is required.";
      isValid = false;
    }

    // Pincode
    if (!Pincode) {
      formErrors.Pincode = "Pincode is required.";
      isValid = false;
    } else if (!/^\d{6}$/.test(Pincode)) {
      formErrors.Pincode = "Pincode must be 6 digits.";
      isValid = false;
    }

    // Mobile No
    if (!MobileNo) {
      formErrors.MobileNo = "Mobile No is required.";
      isValid = false;
    } else if (!/^\d{10}$/.test(MobileNo)) {
      formErrors.MobileNo = "Mobile No must be 10 digits.";
      isValid = false;
    }

    if (!TelephoneNo) {
      formErrors.TelephoneNo = "Telephone No is required.";
      isValid = false;
    }

    // Fax No
    if (!FaxNo) {
      formErrors.FaxNo = "Fax No is required.";
      isValid = false;
    }

    if (!TDSId) {
      formErrors.TDSId = "TDS id is required.";
      isValid = false;
    }

    // PAN Validation
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (!PANNo) {
      formErrors.PANNo = "PAN is required.";
      isValid = false;
    } else if (!panRegex.test(PANNo)) {
      formErrors.PANNo = "Invalid PAN format.";
      isValid = false;
    }

    // GST Validation
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    if (!GSTNo) {
      formErrors.GSTNo = "GST No is required.";
      isValid = false;
    } else if (!gstRegex.test(GSTNo)) {
      formErrors.GSTNo = "Invalid GST format.";
      isValid = false;
    }


    setErrors(formErrors);
    return isValid;
  };


  const resetForm = () => {
    setAccountName('');
    setGroupId('');
    setSubGroupId('');
    setOpeningBalance('');
    setDrOrCr('')
    setTypeCode('');
    setIsSystem('');
    setDepriciation('');
    setAddress1('');
    setAreaId('');
    setCityId('');
    setStateId('');
    setCountryId('');
    setPincode('');
    setTelephoneNo('');
    setMobileNo('');
    setFaxNo('');
    setGSTNo('');
    setPANNo('');
    setIsSubsidiary('');
    setEmailId('');
    setIsTDSApplicable('');
    setIsFBT('');
    setIsFreeze('');
    setTDSId('');



  }


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    if (!validateForm()) return;

    const accountData = {
      AccountName: AccountName,
      GroupId: GroupId,
      SubGroupId: SubGroupId,
      OpeningBalance: OpeningBalance,
      DrORCr: DrORCr,
      TypeCode: TypeCode,
      IsSystem: IsSystem,
      Depriciation: Depriciation
    };

    const addressData = {
      Address1: Address1,
      Address2: Address2,
      Address3: Address3,
      CountryId: CountryId,
      StateId: StateId,
      CityId: CityId,
      AreaId: AreaId,
      Pincode: Pincode,
      TelephoneNo: TelephoneNo,
      MobileNo: MobileNo,
      FaxNo: FaxNo,
      PANNo: PANNo,
      GSTNo: GSTNo,
      EmailId: EmailId,
      TDSId: TDSId,
      IsSubsidiary: IsSubsidiary,
      IsTDSApplicable: IsTDSApplicable,
      IsFBT: IsFBT,
      IsFreeze: IsFreeze
    };


    // Determine the URL based on whether we're editing or adding
    const accounturl = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Accountupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Accountpost.php";

    // If editing, include the author ID in the payload
    if (isEditing) {
      accountData.Id = accountid;
    }

    // Determine the URL based on whether we're editing or adding
    const addressurl = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Addressupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Addresspost.php";

    // If editing, include the author ID in the payload
    if (isEditing) {
      addressData.Id = addressid;

    }


    try {
      await axios.post(accounturl, accountData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });


      await axios.post(addressurl, addressData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });



      if (isEditing) {
        toast.success('Account updated successfully!',);
      } else {
        toast.success('Account added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchAccounts(); // Refresh the list after submit
      fetchAddresses();
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };



  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 10,
        Cell: ({ row }) => row.index + 1,

      },
      {
        accessorKey: "AccountName",
        header: "Account Name",
        size:10
      
      },

      {
        accessorKey: "OpeningBalance",
        header: "Opening Balance",
        size: 10,
      },

      
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                // background: "#3c7291",
                // background: '#023e7d',
                background: '#0a60bd',
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [accounts, addresses]
  );

  const table = useMaterialReactTable({
    columns,
    data: accounts,
    muiTableHeadCellProps: {
      style: {
        // backgroundColor: "#758694", 
        backgroundColor: '#E9ECEF',
        color: 'black',fontSize: '16px'
      },
    },
  });






  return (

    <div className="account-master">
      <h1 >Account Master</h1>

      <div className="accounttable-master">
        <div className="accounttable1-master">
          {" "}
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", 
              fontWeight: "700", 
              // background:'#006989',
              // background:'#023e7d', 
              background: '#0a60bd',
              width:'15%' }}
            >
            New
          </Button>
          <div className="acctable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>


        {isModalOpen && <div className="account-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen}>
          <div className="account-modal"
          >
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
               // color: "#3c7291",
              }}>
              {editingIndex >= 0 ? "Edit Account Master" : "Add Account Master"}
            </h1>







            <Box sx={{ padding: 5 }}>

              <form>
                <Grid container spacing={2}>
                  {/* Left Section */}
                  <Grid item xs={12} sm={6} >

                    <Typography variant="h5" gutterBottom style={{ color: '#3c7291', fontWeight: '500' }}>
                      Account Details:
                    </Typography>
                    <div className="account-form" >

                      <div>
                        <label className="account-label">Account Name<b className="required">*</b></label>
                        <div>
                          <input
                            type="text"
                            id="AccountName"
                            name="AccountName"
                            value={AccountName}
                            onChange={(e) => setAccountName(e.target.value)}
                            placeholder="Enter Account Name"
                            className="account-control"
                          />

                          <div >
                            {errors.AccountName && <b className="error-text">{errors.AccountName}</b>}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className="account-label">Account Group Id<b className="required">*</b></label>
                        <Select
                          id="GroupId"
                          name="GroupId"
                          value={accountgroupOptions.find((option) => option.value === GroupId)}
                          onChange={(option) => setGroupId(option.value)}
                          options={accountgroupOptions}
                          styles={{
                            control: (base) => ({
                              ...base,
                              width: "170px",
                              marginTop: "10px",
                              borderRadius: "4px",
                              border: "1px solid rgb(223, 222, 222)",
                               marginBottom: '5px'
                            }),
                          }}
                          placeholder="Select Group id"
                        />

                        <div>
                          {errors.GroupId && <b className="error-text">{errors.GroupId}</b>}
                        </div>
                      </div>


                      <div>
                        <label className="account-label">Sub Group Id<b className="required">*</b></label>
                        <div>
                          <Select
                            id="SubGroupId"
                            name="SubGroupId"
                            value={subgroupOptions.find((option) => option.value === SubGroupId)}
                            onChange={(option) => setSubGroupId(option.value)}
                            options={subgroupOptions}
                            styles={{
                              control: (base) => ({
                                ...base,
                                width: "170px",
                                marginTop: "10px",
                                borderRadius: "4px",
                                border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                              }),
                            }}
                            placeholder="Select Id"
                          />

                          <div>
                            {errors.SubGroupId && <b className="error-text">{errors.SubGroupId}</b>}
                          </div>
                        </div>
                      </div>


                      <div>
                        <label className="account-label">Opening Balance<b className="required">*</b></label>
                        <div>
                          <input
                            type="text"
                            id="OpeningBalance"
                            name="OpeningBalance"
                            value={OpeningBalance}
                            onChange={(e) => setOpeningBalance(e.target.value)}
                            className="account-control"
                            placeholder="Enter Opening balance"
                          />

                          <div>
                            {errors.OpeningBalance && <b className="error-text">{errors.OpeningBalance}</b>}
                          </div>
                        </div>
                      </div>

                      <div>
                        <label className="account-label">Debit / Credit<b className="required">*</b></label>
                        <div>
                          <input
                            type="text"
                            id="DrORCr"
                            name="DrORCr"
                            value={DrORCr}
                            onChange={(e) => setDrOrCr(e.target.value)}
                            className="account-control"
                            placeholder="1 Digit Letter or Number"
                          />

                          <div>
                            {errors.DrORCr && <b className="error-text">{errors.DrORCr}</b>}
                          </div>
                        </div>
                      </div>

                      <div>
                        <label className="account-label">Type Code<b className="required">*</b></label>
                        <div>
                          <input
                            type="text"
                            id="TypeCode"
                            name="TypeCode"
                            value={TypeCode}
                            onChange={(e) => setTypeCode(e.target.value)}
                            className="account-control"
                            placeholder="1 Digit Letter or Number"
                          />

                          <div>
                            {errors.TypeCode && <b className="error-text">{errors.TypeCode}</b>}
                          </div>
                        </div>
                      </div>



                      <div>
                        <label className="account-label">Depriciation<b className="required">*</b></label>
                        <div>
                          <input
                            type="text"
                            id="Depriciation"
                            name="Depriciation"
                            value={Depriciation}
                            onChange={(e) => setDepriciation(e.target.value)}
                            className="account-control"
                            placeholder="Enter Depreciation in %"
                          />
                          <div>
                            {errors.Depriciation && <b className="error-text">{errors.Depriciation}</b>}
                          </div>
                        </div>
                      </div>


                      <div>
                        <label className="account-label">TDS Id<b className="required">*</b>:</label>
                        <div>
                          <Select
                            id="TDSId"
                            name="TDSId"
                            value={tdsOptions.find((option) => option.value === TDSId)}
                            onChange={(option) => setTDSId(option.value)}
                            options={tdsOptions}
                            styles={{
                              control: (base) => ({
                                ...base,
                                width: "170px",
                                marginTop: "10px",
                                borderRadius: "4px",
                                border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                              }),
                            }}
                            placeholder="Select TDS id"
                          />
                          <div >
                            {errors.TDSId && <b className="error-text">{errors.TDSId}</b>}
                          </div>
                        </div>
                      </div>




                    </div>


<div className="account-form" style={{marginTop: '30px'}}>
  
<div>
                <label className="account-label">Is Subsidiary</label>
                  <input
                  type="checkbox"
                    id="IsSubsidiary"
                    name="IsSubsidiary"
                    checked={IsSubsidiary}
                    onChange={(e) => setIsSubsidiary(e.target.checked)}
                   
                  />                  
<div>
{errors.IsSubsidiary && <b className="error-text">{errors.IsSubsidiary}</b>}
</div>
              </div>
              <div>
                <label className="account-label"                     style={{marginTop: '30px'}}
                >Is TDS Applicable</label>
                <input
                  type="checkbox"
                    id="IsTDSApplicable"
                    name="IsTDSApplicable"
                    checked={IsTDSApplicable}
                    onChange={(e) => setIsTDSApplicable(e.target.checked)}
                   
                  />               

   <div>
{errors.IsTDSApplicable && <b className="error-text">{errors.IsTDSApplicable}</b>}
</div>
              </div>

              <div>
                <label className="account-label">Is FBT</label>
                  <input
                  type="checkbox"
                    id="IsFBT"
                    name="IsFBT"
                    checked={IsFBT}
                    onChange={(e) => setIsFBT(e.target.checked)}
                  />                  

<div>
{errors.IsFBT && <b className="error-text">{errors.IsFBT}</b>}
</div>
              </div>

              <div style={{marginTop: '10px'}}>
                <label className="account-label">Is Freeze</label>
                  <input
                  type="checkbox"
                    id="IsFreeze"
                    name="IsFreeze"
                    checked={IsFreeze}
                    onChange={(e) => setIsFreeze(e.target.checked)}
                   

                  />                  

<div>
{errors.IsFreeze && <b className="error-text">{errors.IsFreeze}</b>}
</div>
                </div>
              
              
              <div style={{marginTop: '10px'}}>
                <label className="account-label">Is System</label>
                  <input
                    type="checkbox"
                    id="IsSystem"
                    name="IsSystem"
                    checked={IsSystem}
                    onChange={(e) => setIsSystem(e.target.checked)}

                  />

<div>
                  {errors.IsSystem && <b className="error-text">{errors.IsSystem}</b>}
                    </div>
              </div>
</div>
          
          
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} sm={6}>

          <Typography variant="h5" gutterBottom style={{color: '#3c7291', fontWeight: '500'}}>
       Address Details:
      </Typography>



      <label onClick={handleLabelClick} style={{ cursor: 'pointer', fontWeight: '500' }}>
  
   Address<b className="required">*</b>
</label> <br/> 


<label style={{ color: 'teal', marginBottom: '30px', fontWeight: '600',  }}>
  Click on the <label style={{color:'red'}}>Address</label> label to show the address fields and add an address.
</label>


 {isAddressVisible && (
            <div  className="address-fields">
              <div>
                <input
                  type="text"
                  id="Address1"
                  name="Address1"
                  value={Address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  style={{ marginRight: "10px" }}
                  className="account-control"
                  placeholder="Enter Address Line 1"
                />
                {errors.Address1 && <b className="error-text">{errors.Address1}</b>}
              </div>
              <div>
                <input
                  type="text"
                  id="Address2"
                  name="Address2"
                  value={Address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  style={{ marginRight: "10px" }}
                  className="account-control"
                  placeholder="Enter Address Line 2"
                />
                {errors.Address2 && <b className="error-text">{errors.Address2}</b>}
              </div>
              <div>
                <input
                  type="text"
                  id="Address3"
                  name="Address3"
                  value={Address3}
                  onChange={(e) => setAddress3(e.target.value)}
                  style={{ marginRight: "10px" }}
                  className="account-control"
                  placeholder="Enter Address Line 3"
                />
                {errors.Address3 && <b className="error-text">{errors.Address3}</b>}
              </div>
            </div>
          )}


<div className="account-form" style={{marginTop: '30px'}}>
<div>
                <label className="account-label">Country<b className="required">*</b></label>
                <div>
                  <Select
                   id="CountryId"
                   name="CountryId"
                   value={countryOptions.find((option) => option.value === CountryId)}
                   onChange={(option) => setCountryId(option.value)}
                   options={countryOptions} 
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                    placeholder="Select country"
                  />                 
                  <div > 
                  {errors.AreaId && <b className="error-text">{errors.AreaId}</b>}
                    </div>
                </div>
              </div>


              
              <div>
                <label className="account-label">State<b className="required">*</b></label>
                <div>
                  <Select
                    id="StateId"
                    name="StateId"
                    value={stateOptions.find((option) => option.value === StateId)}
                    onChange={(option) => setStateId(option.value)}
                    options={stateOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                        marginTop: "10px",
                        borderRadius: "4px",
                        border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                      }),
                    }}
                    placeholder="Select State"
                  />                  

<div>
                  {errors.StateId && <b className="error-text">{errors.StateId}</b>}
                    </div>

                </div>
              </div>


              <div>
                <label className="account-label">City<b className="required">*</b></label>
                <div>
                  <Select
                    id="CityId"
                    name="CityId"
                    value={cityOptions.find((option) => option.value === CityId)}
                    onChange={(option) => setCityId(option.value)}
                    options={cityOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                        marginTop: "10px",
                        borderRadius: "4px",
                        border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                      }),
                    }}
                    placeholder="Select City"
                  />                 

<div>
                  {errors.CityId && <b className="error-text">{errors.CityId}</b>}
                    </div>

                </div>
              </div>

              <div>
                <label className="account-label">Area<b className="required">*</b></label>
                <div>
                  <Select
                   id="AreaId"
                   name="AreaId"
                   value={areaOptions.find((option) => option.value === AreaId)}
                   onChange={(option) => setAreaId(option.value)}
                   options={areaOptions} 
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                    placeholder="Select Area"
                  />                 
                  <div > 
                  {errors.AreaId && <b className="error-text">{errors.AreaId}</b>}
                    </div>
                </div>
              </div>
              <div>
                <label className="account-label">Pincode<b className="required">*</b></label>
                <div>
                  <input type="text"
                    id="Pincode"
                    name="Pincode"
                    value={Pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    className="account-control"
                    placeholder="Enter Pincode"
                  />                  

<div>
                  {errors.Pincode && <b className="error-text">{errors.Pincode}</b>}
                    </div>

                </div>
              </div>

              <div>
                <label className="account-label">Telephone No<b className="required">*</b></label>
                <div>
                  <input

                  type="text"
                    id="TelephoneNo"
                    name="TelephoneNo"
                    value={TelephoneNo}
                    onChange={(e) => setTelephoneNo(e.target.value)}
                    className="account-control"
                    placeholder="Enter Tel No"
                  />                 

<div>
                  {errors.TelephoneNo && <b className="error-text">{errors.TelephoneNo}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="account-label">Mobile No<b className="required">*</b></label>
                <div>
                  <input
                  type="text"
                    id="MobileNo"
                    name="MobileNo"
                    value={MobileNo}
                    onChange={(e) => setMobileNo(e.target.value)}
                    className="account-control"
                    placeholder="Enter Mob No"
                  />                 

<div>
                  {errors.MobileNo && <b className="error-text">{errors.MobileNo}</b>}
                    </div>

                </div>
              </div>
              <div>
                <label className="account-label">Fax No<b className="required">*</b></label>
                <div>
                  <input
                  type="text"
                    id="FaxNo"
                    name="FaxNo"
                    value={FaxNo}
                    onChange={(e) => setFaxNo(e.target.value)}
                    className="account-control"
                    placeholder="Enter Fax No"
                  />                  

<div>
{errors.FaxNo && <b className="error-text">{errors.FaxNo}</b>}
</div>
                </div>
              </div>

              <div>
                <label className="account-label">PAN No<b className="required">*</b></label>
                <div>
                  <input
                  type="text"
                    id="PANNo"
                    name="PANNo"
                    value={PANNo}
                    onChange={(e) => setPANNo(e.target.value)}
                    className="account-control"
                    placeholder="Enter Pan No"
                  />                  

<div>
{errors.PANNo && <b className="error-text">{errors.PANNo}</b>}
</div>
                </div>
              </div>

              <div>
                <label className="account-label">GST No<b className="required">*</b></label>
                <div>
                  <input
                  type="text"
                    id="GSTNo"
                    name="GSTNo"
                    value={GSTNo}
                    onChange={(e) => setGSTNo(e.target.value)}
                    className="account-control"
                    placeholder="Enter GST No"
                  />                  

<div>
{errors.GSTNo && <b className="error-text">{errors.GSTNo}</b>}
</div>
                </div>
              </div>

              <div>
                <label className="account-label">Email Id<b className="required">*</b></label>
                <div>
                  <input
                  type="text"
                    id="EmailId"
                    name="EmailId"
                    value={EmailId}
                    onChange={(e) => setEmailId(e.target.value)}
                    className="account-control"
                    placeholder="Enter EmailId"
                  />                  

<div>
{errors.EmailId && <b className="error-text">{errors.EmailId}</b>}
</div>
                </div>
              </div>


</div>

     

           
          </Grid>
        </Grid>
        <div className="acc-btn-container">
                  <Button
                    onClick={handleSubmit}
                    style={{
                      // background: "#3c7291",
                      background:"#0a60bd",
                      alignContent: 'center',
                      color: "white",
                    }}
                    >
                             {editingIndex >= 0 ? "Update" : "Save"}

                  </Button>
                  <Button
                    onClick={() => setIsModalOpen(false)}
                    style={{
                      background: "red",
                      color: "white",
                    }}>
                    Cancel
                  </Button>
              </div>
      
      </form>
    </Box>
              
          </div>
        </Modal>
      </div>

      <ToastContainer/>
    </div>
  );
}

export default Accounts;
