import React from 'react';
import '../Pages/Home.css'

function Home(){
return (
  <div className='home-container'>
    <h3 style={{color: 'orange'}}>

     Home Page
    </h3>
  </div>
)
}


export default Home;