import React from 'react'

function Companymaster() {
  return (
    <div>
      Companymaster
      Companymaster
    </div>
  )
}

export default Companymaster
